import { Fragment } from "react"
import {Row, Col} from "react-bootstrap"
import Classes from "./style.module.css"

const ScheduleRepeatOnWeek = (props) =>{
    const {weekIndex, weekName, weekDays, setWeekDays}=props
    const weekDaySelectionHandler = (weekDayNo) =>{
        
        let isDayExist = isWeekDaySelected()
        console.log('selected day - ', weekDayNo, isDayExist)
        if(isDayExist){
            if(weekDays.length===1)return
            //remove day from array
            console.log('removing a item from array...', weekIndex)
           let newWeekDays = weekDays.filter((day)=>+day!==+weekDayNo)
           console.log('after removed arr - ', newWeekDays)
           setWeekDays(newWeekDays)
        }else{
            console.log('added new day in array...', weekIndex)
            setWeekDays((prev)=>([...prev,weekIndex]))
        }
        
        //console.log('added days... ', weekDays)
    }
    //console.log('loading week days .... ', weekDays)

    const isWeekDaySelected = () =>{
        try{
            let isExist = weekDays.findIndex((day)=>day===weekIndex)
            console.log('Check array item exist or not ', isExist, weekDays)
             
            if(isExist>=0){
                return true
            }else{
                return false
            }
        }catch(error){
            console.log(error)
        }
    }

    const isActive = () =>{
        try{
            let isExist = weekDays.findIndex((day)=>day===weekIndex)
            
            if(isExist>=0){
                return true
            }else{
                return false
            }
        }catch(error){
            console.log(error)
        }
    }

    return (
        <Fragment>
            <Col className={Classes.weekDays}>
                <span className={isActive()?Classes.active:''}  onClick={()=>weekDaySelectionHandler(weekIndex)}>
                    {weekName}
                </span>
            </Col>
        </Fragment>
        
    )
}

export default ScheduleRepeatOnWeek