import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "react-bootstrap";
import { saveAs } from "file-saver";
import { getCampEmailResponseByID, getCampEmailResponseByFilter } from "../../store/slices/reportCamp/reportCamp";
import Layout from "../../layouts/default";
import DateFilter from "./DateFilter";

const CampaignReportPage = () => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;

  // Get email response data from Redux store
  const emailResponseByID = useSelector(
    (state) => state.emailResponseSlice.emailResponseByID
  ) || [];

  const { campaignName, campaignType } = location.state || {};

  // Fetch the campaign data when the component mounts or campaignId changes
  useEffect(() => {
    const fetchCampaignData = () => {
      setLoading(true);
      dispatch(getCampEmailResponseByID(campaignId));
      setLoading(false);
    };

    fetchCampaignData();
  }, [dispatch, campaignId]);

  // Function to handle applying the date filter
  const applyFilter = ({ from, to }, page = currentPage) => {
    setLoading(true);
    dispatch(
      getCampEmailResponseByFilter({ campaignId, from, to, page })
    ).finally(() => {
      setLoading(false);
    });
  };

  // Function to reset filters and fetch initial data
  const handleReset = () => {
    setLoading(true);
    setCurrentPage(1); // Reset to first page
    dispatch(getCampEmailResponseByID(campaignId)).finally(() => {
      setLoading(false);
    });
  };

  // Handle pagination
  const handleNextPage = () => {
    if (currentPage < Math.ceil(emailResponseByID.length / dataPerPage)) {
      setCurrentPage(currentPage + 1);
      applyFilter({ from: "", to: "" }, currentPage + 1); // Apply filter for next page
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      applyFilter({ from: "", to: "" }, currentPage - 1); // Apply filter for previous page
    }
  };

  // Function to handle CSV export
  const exportToCSV = () => {
    const headers = "S.N,Campaign Type,Trigger Date,Sent Mail,Failed Mail,Total Mail Count\n";
    const csvData = emailResponseByID.map((log, index) => (
      `${index + 1},${log.channel?.name || "Email"},${new Date(log.createdAt).toLocaleDateString()},${log.Success_count || 0},${log.Failed_count || 0},${log.total || 0}\n`
    ));
    const blob = new Blob([headers + csvData.join("")], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `campaign_report_${campaignId}.csv`);  
  };

  // Pagination logic
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = emailResponseByID.slice(indexOfFirstData, indexOfLastData);

 

  return (
    <Layout>
      
      <div>
        {/* Date Filter and Reset Button */}
        <div className="d-flex align-items-center justify-content-between pt-2">
          <DateFilter applyFilter={applyFilter} resetFilter={handleReset} />

          <Button
            onClick={exportToCSV}
            disabled={currentData.length==0}
            className="mb-3 ml-3"
            variant="primary"
          >
            Export
          </Button>
        </div>

        {/* Campaign Report Table */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.N</th>
              <th>Campaign Type</th>
              <th>Trigger Date</th>
              <th>Sent Mail</th>
              <th>Failed Mail</th>
              <th>Total Mail Count</th>
            </tr>
          </thead>
          <tbody>
            {currentData.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">No data available</td>
              </tr>
            ) : (
              currentData.map((log, index) => (
                <tr key={index}>
                  <td>{indexOfFirstData + index + 1}</td>
                  <td>{log.channel?.name || campaignType || "Email"}</td>
                  <td>{new Date(log.createdAt).toLocaleDateString()}</td>
                  <td>{log.Success_count || 0}</td>
                  <td>{log.Failed_count || 0}</td>
                  <td>{log.total || 0}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between">
          <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </Button>
          <span>Page {currentPage} of {Math.ceil(emailResponseByID.length / dataPerPage)}</span>
          <Button onClick={handleNextPage} disabled={currentPage === Math.ceil(emailResponseByID.length / dataPerPage)}>
            Next
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default CampaignReportPage;
