import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import {getToken} from "../../helpers/common";

const getCampaigns = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {page, filterData};
    console.log("data", data);
    let result = await apiService.post(API_URL.GET_CAMPAIGNS, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const getCampaign = async (id) => {
  try {
    let token = getToken();

    let result = await apiService.get(API_URL.GET_CAMPAIGN + id, token);
    return result;
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const getCreateCampaign = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("CREATE campaign data #okkkkkkk", data);
    let result = await apiService.post(API_URL.CAMPAIGN_CREATE, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const getUpdateCampaign = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("data", data);
    let result = await apiService.put(API_URL.CAMPAIGN_UPDATE, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const getCampaignRecurrence = async (id) => {
  try {
    let token = getToken();

    let result = await apiService.get(
      API_URL.GET_CAMPAIGN_RECURRENCE + id,
      token
    );
    return result;
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const getUpdateCampaignRecurrence = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("data", data);
    let result = await apiService.put(
      API_URL.CAMPAIGN_RECURRENCE_UPDATE,
      data,
      token
    );
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const getCreateCampaignRecurrence = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("CREATE campaign recurrence data #okkkkkkk", data);
    let result = await apiService.post(
      API_URL.CAMPAIGN_RECURRENCE_CREATE,
      data,
      token
    );
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};
const deleteCampaign = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("delete campaign #okkkkkkk", data);
    let result = await apiService.post(API_URL.CAMPAIGN_DELETE, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};
const cloneCampaign = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("clone campaign #okkkkkkk", data);
    let result = await apiService.post(API_URL.CAMPAIGN_CLONE, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};
const testCampaign = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("clone campaign #okkkkkkk", data);
    let result = await apiService.post(API_URL.TEST_CAMPAIGN, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const getCampaignErrors = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();

    data = {page, filterData};
    console.log("getCampaignErrors service #okkkkkkk", data);
    let result = await apiService.post(API_URL.CAMPAIGN_ERRORS, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};
const getCampaignErrorCount = async () => {
  try {
    let data = {};

    let token = getToken();

    data = {};
    console.log("getCampaignErrorCount service #okkkkkkk", data);
    let result = await apiService.post(API_URL.CAMPAIGN_ERROR_COUNT, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const campaignsService = {
  getCampaigns,
  getCampaign,
  getCreateCampaign,
  getUpdateCampaign,
  getCampaignRecurrence,
  getUpdateCampaignRecurrence,
  getCreateCampaignRecurrence,
  deleteCampaign,
  cloneCampaign,
  testCampaign,
  getCampaignErrors,
  getCampaignErrorCount
};

export default campaignsService;
