import { useFormik } from "formik";
import { Row, Col, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {createBrowserHistory} from "history"

const BlockContactFilterForm = (props) => {
    
  const { setFilterData, setPage, template,onAddNewBlock, scopeType } = props;
  const initialValues = {
    name: "",
    status: "",
    channel:"",
    scopeType:scopeType
  };

  const getBlockOptions = () => {
    try{
        let blockTypes = ['Mobile', 'Email', 'Business','Defaulter Individual','All Defaulter of Supplier', 'Users Self','Users - All Business']
        return blockTypes.map((blocktype)=><option key={blocktype} value={blocktype} label={blocktype}>{blocktype}</option>)
    }catch(error){
        console.log(error)
    }
  }
  
  const formik = useFormik({
    initialValues,

    onSubmit: values => {
      values.scopeType = scopeType
      if (Object.keys(values).length) {
        console.log('search payload data........... valuesssssssssss', values, scopeType)
        Object.keys(values).map((filterKey) => {
          if (!values[filterKey]) {
            delete values[filterKey];
          }
          return "";
        });
      }
      console.log('search payload data...........', values)
      setFilterData(values);
      setPage(1)
      createBrowserHistory().push(`/${template}?page=1`);
    },
  });

  const handleReset = () => {
    props.setLoading(false);
    setFilterData("");
    formik.values.blockValue = "";
    formik.values.status = "";
    formik.values.blockType = "";
  };

  const addNewBlockHandler = () =>{
    onAddNewBlock(true)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row className="mb-3">
        <Col md={4}>
          <div className="form-floating">
            <Button className="btn btn-primary" onClick={addNewBlockHandler}>Add to Block</Button>
          </div>
        </Col>
        <Col md={8}>
          <Row>
            
            <Col>
            
              <label htmlFor="email">Block Type</label>
              <select
                className="form-select"
                name="blockType"
                value={formik.values.blockType}
                onChange={formik.handleChange}
                style={{ display: "block" }}
              >
                <option value='' label="Select a Block type">Select a Block type{" "}</option>
                {getBlockOptions()}
              </select>
            </Col>
            {/* <Col>
              <label htmlFor="name">Block Value</label>
              <input
                name="blockValue"
                id="name"
                type="text"
                className="form-control"
                placeholder="Enter search key"
                onChange={formik.handleChange}
                value={formik.values.blockValue}
              />
            </Col> */}
            <Col>
              <label htmlFor="status">Status</label>
              <select
                className="form-select"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                style={{ display: "block" }}
              >
                <option value="" label="Select a Status">
                  Select Status{" "}
                </option>
                <option value="0" label="Inactive">
                  {" "}
                  Inactive
                </option>
                <option value="1" label="Active">
                Active
                </option>
              </select>
            </Col>
          </Row>
          
          <Row className="mt-3">
            <Col>
              <Button as="input" type="submit" value="Filter" xs={4} md={1} />{" "}
              <Button onClick={handleReset} type="reset" variant="secondary">
                Reset
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

export default BlockContactFilterForm;