import React from "react";
import * as S from "./style";

const CustomNavigation = (props) => {
  
  // const activeRoute = (routeName) => {
  //   return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  // };
  return (
    <>
      <S.NavWrapper
        activeKey="/app"
        onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
      >
        <S.NavItem>
          <S.NavLink to="/app">Dashboard</S.NavLink>
        </S.NavItem>

        <S.NavItem>
          <S.NavLink to="/campaigns">Campaigns</S.NavLink>
        </S.NavItem>
        <S.NavItem>
          <S.NavLink to="/users">Users</S.NavLink>
        </S.NavItem>
        <S.NavItem>
          <S.NavLink to="/templates">Templates</S.NavLink>
        </S.NavItem>
        <S.NavItemHeading>Block Contacts</S.NavItemHeading>
        <S.NavItem>
          <S.NavLink to="/block-contacts/global">Global</S.NavLink>
        </S.NavItem>

        <S.NavItem>
          <S.NavLink to="/block-contacts/campaign">By Campaigns</S.NavLink>
        </S.NavItem>
        <S.NavItem>
          <S.NavLink to="/block-contacts/tags">By Campaign Tag</S.NavLink>
        </S.NavItem>
      
        <S.NavItem>
          <S.NavLink to="/campaign/report">Report</S.NavLink>
        </S.NavItem>
        
      </S.NavWrapper>
    </>
  );
};

export default CustomNavigation;
