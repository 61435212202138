import { useFormik } from 'formik';
import {Button} from "react-bootstrap"
import { useDispatch } from 'react-redux';
import {createBlockContact, updateBlockContact} from "../../store/slices/blockContacts/create"
import SupplierSelector from './SupplierSelector';
import DefaulterSelector from './DefaulterSelector';
import { useState, useEffect } from 'react';
import campaignService from "../../services/campagins";
import CampaignSelector from './CampaignSelector';
import CampaignTagsSelector from "../CampaignTags/Selector"
import campaignTagsService from "../../services/campaignTags";

const getBlockValue = (contact,type) =>{
    let blockValue = ''
    if(contact?.id){
        console.log('Edit defaulter indivusaul........',contact.blockType)
        if(type==='supplier' && contact.blockType==='Defaulter Individual'){
            blockValue = {label:contact.business.supplier_trade_name+` - ${contact.business.supplier_gst_no}`, value:contact.business.submitted_by}
        }else if(type==='supplier' && contact.blockType==='All Defaulter of Supplier'){
            blockValue = {label:contact.business.trade_name+` - ${contact.business.gst_no}`, value:contact.blockValue} 
        }else{
            blockValue = {label:contact.business.buyer_trade_name+` - ${contact.business.buyer_gst_no}`, value:contact.blockValue}
        }
    
    }
    return blockValue
}

const getBlockStatus = (contact) =>{
    let blockStatus = 1
    if(contact?.id){
        blockStatus = contact.status 
    }
    return blockStatus
}

const BlockContactFormDefaulter = (props) =>{
    const {contact, scopeType, blockType, onAddUpdate} = props
    const [selectedDefaulter, setSelectedDefaulter] = useState(getBlockValue(contact,'defaulter'))
    const [selectedSupplier, setSelectedSupplier] = useState(getBlockValue(contact,'supplier'))
    const [isValid, setIsValid] = useState(true)
    const [isValidSupplier, setIsValidSupplier] = useState(true)
    const [status,setStatus] = useState(getBlockStatus(contact))
    const [selectedCampaign, setSelectedCampaign] = useState('')
    const [isCampaignValid, setIsCampaignValid] = useState(true)
    const [selectedTag, setSelectedTag] = useState('')
    const [isValidTag, setIsValidTag] = useState(true)
    const dispatch = useDispatch()

    const defaulterChangeHandler = (event) =>{
        try{
            console.log('selected defaulter ', event)
            setSelectedDefaulter(event)
            setIsValid(true)
        }catch(error){
            console.log(error)
        }
    }

    const showDefaulterIndivisual = ((blockType==='Defaulter Individual' && selectedSupplier) || (contact && blockType==='Defaulter Individual'))?true:false;
    //const shouldSupplierShow = (blockType==='All Defaulter of Supplier' || (!contact && blockType==='Defaulter Individual'))?true:false;
    
    const supplierChangeHandler = (event) =>{
        try{
            setSelectedSupplier(event)
            setIsValidSupplier(true)
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        if(scopeType==='Campaign'){
          getCampaignInfo()
        }
        if(scopeType==='Tags'){
          getTagsInfo()
        }
        
      },[])
  
      const getCampaignInfo = async () =>{
        try{
          let blockValue = ''
          if(contact?.id){
              let filterData = {id:contact.scopeValue}
              let selectedResult = await campaignService.getCampaigns(1, filterData)
              console.log('Selected Result....',selectedResult, selectedResult.data[0].name)
              blockValue = {label:selectedResult.data[0].name, value:contact.scopeValue} 
              setSelectedCampaign(blockValue)
          }
          
        }catch(error){
          console.log()
        }
      }
      const getTagsInfo = async () =>{
        try{
          let blockValue = ''
          if(contact?.id){
              let selectedResult = await campaignTagsService.getCampaignTag(contact.scopeValue)
              blockValue = {label:selectedResult.data.name, value:contact.scopeValue} 
              setSelectedTag(blockValue)
          }
          
        }catch(error){
          console.log()
        }
      }

    const campaignChangeHandler = (event) =>{
        try{
            console.log('selected supplier ', event)
            setSelectedCampaign(event)
            setIsCampaignValid(true)
        }catch(error){
            console.log(error)
        }
    }

    const tagChangeHandler = (event) =>{
        try{
            console.log('selected tag ', event)
            setSelectedTag(event)
            setIsValidTag(true)
        }catch(error){
            console.log(error)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            scopeType:  scopeType,
            scopeValue:0,
            blockType:blockType,
            blockValue:blockType==='Defaulter Individual'?selectedDefaulter:selectedSupplier,
            status:status
        },
        
        onSubmit: (values, {resetForm}) => {
            
            if(scopeType==='Campaign' && !selectedCampaign){
                setIsCampaignValid(false)
                return
            }
            if(scopeType==='Campaign' && selectedCampaign){
                values.scopeValue = selectedCampaign.value
            }

            //block contact by campaign tags
            if(scopeType==='Tags' && !selectedTag){
                setIsValidTag(false)
                return
            }
            if(scopeType==='Tags' && selectedTag){
                values.scopeValue = selectedTag.value
            }

            let blockVal = blockType==='Defaulter Individual'?selectedDefaulter:selectedSupplier
            
            if(!selectedSupplier){
                setIsValidSupplier(false)
                return 
            }

            if(!blockVal && blockType==='Defaulter Individual'){
                setIsValid(false)
                return 
            }

            values.blockValue = blockVal.value
            console.log('submitted values #########',values);
            if(contact?.id){
              values.id = contact.id
              dispatch(updateBlockContact(values));
            }else{
              dispatch(createBlockContact(values));
            }
            
            onAddUpdate()
            resetForm({values:''})
        },
   
      });
      return(
        <form onSubmit={formik.handleSubmit}>
            {scopeType==='Campaign' && <div className="form-group row mb-3">
            <label htmlFor="name" className="col-md-3 col-form-label">Campaign</label>
            <div className="col-md-9">
                <CampaignSelector 
                onTagChange={campaignChangeHandler}
                selectedVal={selectedCampaign}
                />
                {  !isCampaignValid ? (
            <p className="error text-danger">
                Please select a Campaign.
            </p>
            ) : null}
            </div>
        </div>}
        {scopeType==='Tags' && <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">Campaign Tag</label>
                <div className="col-md-9">
                   <CampaignTagsSelector 
                   onTagChange={tagChangeHandler}
                   selectedTags={selectedTag} 
                   multiOption={false} 
                   showLabel={false}
                   />
                    {  !isValidTag ? (
                <p className="error text-danger">
                  Please select a Tag.
                </p>
              ) : null}
                </div>
            </div>}
          <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">Supplier</label>
                <div className="col-md-9">
                   <SupplierSelector 
                   onTagChange={supplierChangeHandler}
                   selectedVal={selectedSupplier}
                   />
                    {  !isValidSupplier ? (
                <p className="error text-danger">
                  Please select a Supplier.
                </p>
              ) : null}
                </div>
            </div>

            {showDefaulterIndivisual && <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">Defaulter</label>
                <div className="col-md-9">
                   <DefaulterSelector 
                   onTagChange={defaulterChangeHandler}
                   selectedVal={selectedDefaulter} 
                   supplier={selectedSupplier?.value}
                   />
                    {  !isValid ? (
                <p className="error text-danger">
                  Please select a Defaulter.
                </p>
              ) : null}
                </div>
               
            </div>}
            <div className="form-group row mt-3">
                <label htmlFor="email" className="col-sm-3 col-form-label">Status</label>
                <div className="col-sm-9">
                    <div className="form-check form-check-inline">
                        <input 
                        className="form-check-input" 
                        type="radio" 
                        name="status" 
                        id="statusActive" 
                        onChange={(event)=>{
                            setStatus(event.currentTarget.value)
                        }}
                        value='1' 
                        checked={+status===1?true:false} />
                        <label className="form-check-label" htmlFor="statusActive">Active</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input 
                        className="form-check-input" 
                        type="radio" 
                        name="status" 
                        id="statusInctive" 
                        checked={+status===0?true:false}
                        value='0' 
                        onChange={(event)=>{
                            setStatus(event.currentTarget.value)
                        }} 
                        />
                        <label className="form-check-label" htmlFor="statusInctive">Inactive</label>
                    </div>
                </div>
                
            </div>
            <div id="btnWrapper" className='text-center mt-4'>
                <Button variant='primary' type="submit">Submit</Button>
            </div>
        </form>
    )
}

export default BlockContactFormDefaulter;