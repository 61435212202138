import { useEffect, useState} from "react";

import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../layouts/default";
import Classes from "./style.module.css"
import {Row, Col} from "react-bootstrap"
import ProgressBar from "../../components/Campaigns/ProgressBar";
import CampaignFooter from "../../components/Campaigns/CampaignFooter"
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";

import { updateCampaign, loadCampaign } from "../../store/slices/campaigns/createCampaign";
import { 
    loadCampaignRecurrence 
} from "../../store/slices/campaigns/createCampaignRecurrence";
import { getTemplates } from "../../store/slices/templates/templates";
import TemplateSelector from "../../components/Template/TemplateSelector"
import { getCampaigns } from "../../store/slices/campaigns/campaigns";


const SetCampaignTemplate = () => {
    const [isTemplateValid, setIsTemplateValid] = useState(true)
    const { campaign, updateCampData } = useSelector((state) => state.createCampaignSlice);
    const {templates}  = useSelector((state) => state.templatesSlice);
    const [selectedTemplate, setSelectedTemplate] = useState({label:'Select Template', value:''})
    const [templateFor, setTemplateFor] = useState('')
    const [refreshStep, setRefreshStep] = useState(false)
    const { campaignRecurrence } = useSelector((state) => state.createCampaignRecurrenceSlice);

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    
    let templateSchema = Yup.object().shape({
        id: Yup.number().required('Name is required.'),
        template: Yup.number().required('Template is required.'),
    });

    useEffect(()=>{
        
        if(campaign?.data?.channel?.name){
            let templateFor = campaign?.data?.channel?.name ? campaign.data.channel.name:'email';
            setTemplateFor(templateFor)
            let payload = {page:1, filterData:{status:'published',channel:{id:campaign.data.channel.id}}, isAll:'yes'}
            console.log('template filter payload........', payload)
            dispatch(getTemplates(payload))
        }
        
    },[dispatch, campaign])

    useEffect(()=>{
        let campaignId = params.id
        if(campaignId){
            dispatch(loadCampaign(campaignId))
            console.log('loading campain data from step third !!!')
        }
        
    },[params.id, dispatch, refreshStep])

    useEffect(()=>{
        let campaignId = params.id
        if(campaignId){
            dispatch(loadCampaignRecurrence(campaignId))
        }

    },[params.id])
 
    const submitHandler = async (campaignStatus) => {
        try{
            let payload = {
                template:selectedTemplate.value,
                id:campaign?.data.id,
                isRepeat:campaign?.data.isRepeat
            }
            setIsTemplateValid(true)
           await templateSchema.isValid(payload).then(function (valid) {
                if(valid){
                    
                    let status = campaignStatus?campaignStatus:'published'
                    if(!campaign?.data?.isRepeat){
                        payload.status=status
                    }
                    dispatch(updateCampaign(payload))
                    //check if isRepeat is true then move to schedule page else return to campaign list page
                    if(campaign?.data?.isRepeat){
                        console.log('move to schedule page', campaign?.data?.isRepeat)
                        navigate('/campaigns/edit/schedule/'+campaign?.data?.id)
                        return
                    }else{
                       //getCampaigns to refresh campaign list page
                        dispatch(getCampaigns({ page:1, filterData:'' })); 
                        setTimeout(()=>{navigate('/campaigns')},200)
                       console.log('move to schedule Publish campaign', campaign?.data?.isRepeat)
                    }
                    
                }else{
                    setIsTemplateValid(false)
                }
            }).catch((error)=>{
                console.log('Eroorrrrrrrrrr - ',error)
            })
            
        }catch(error){
            console.log('Error:', error)
        }
    }

    return (
        <Layout>
            <Row>
                <Col md={8}>
                    <div className="pageHeader mb-6">
                        <h3 className="smallHeading mb-3">Create Campaign</h3>
                        <h1>Select Template</h1>
                        
                    </div>
                    <div className="progressSteps mt-5 mb-5">
                        <TemplateSelector 
                        templates={templates} 
                        selectedTemplate={selectedTemplate} 
                        setSelectedTemplate={setSelectedTemplate}  
                        campaign={campaign}  templateFor={templateFor} 
                        setRefreshStep={setRefreshStep}
                        />
                        {!isTemplateValid && <p className="text-danger pt-3 text-center">Please select or create Template.</p>}
                    </div>
                    <CampaignFooter 
                        nextStep="Schedule the campaign" 
                        buttonTitle={campaign?.data?.isRepeat ? "Set Template" : "Save & Publish"} 
                        onNextStepHandler={submitHandler} 
                        showDraft={!campaign?.data?.isRepeat}
                     />
                </Col> 
                <Col md={4} className={Classes.sidebarBackground}>
                    <div className={Classes.sidebarBackground} style={{height:'100VH'}}>
                        <ProgressBar 
                        campaign={campaign} 
                        updateCampData={updateCampData} 
                        campaignRecurrence={campaignRecurrence}
                        />
                    </div>
                    
                </Col>
            </Row>
        </Layout>
    )
}

export default SetCampaignTemplate;