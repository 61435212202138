import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import creditqService from "../../../services/creditq";

export const getBusinesses = createAsyncThunk(
  "creditq/getBusinesses",
  async (payload, thunkAPI) => {
    try {
        console.log('getBusinesses load filter in thunk .....', payload)
      const result = await creditqService.getBusinesses(
        payload.page,
        payload.filterData
      );
      console.log('get getBusinesses thunk calling....', result)
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { businessess: null };

const businessessSlice = createSlice({
  name: "businessess",
  initialState,

  extraReducers: {
    [getBusinesses.fulfilled]: (state, action) => {
      console.log("getBusinesses.fulfilled...", action.payload);
      state.businessess = action.payload;
    },
    [getBusinesses.rejected]: (state, action) => {
      state.businessess = null;
    },
  },
});

export default businessessSlice.reducer;
