import { useEffect, useState } from "react";
import AsyncSelect  from "react-select/async"
import creditService from "../../services/creditq";

const UserSelector = (props) =>{
    const {onTagChange, selectedVal} = props
    const [users, setUsers]=useState([])
    
    useEffect(()=>{
        getUsers()
    },[]) 
    
    const getUsers = async () =>{
        let results = await creditService.getUsers(1, '')
        console.log('suppluer Selectes okkkkkkkkkk ',results)
        setUsers(results)
    }

    const getAllOptions = () =>{
        
        let optionsList = []
        if(users?.data?.length){
            users.data.map((item)=>{
                optionsList.push({label:`${item.name} - ${item.mobile}`, value:item.id})
                return ''
            })
        }
        
        return optionsList
    }

    const filterList = async (searchKey) =>{
        let optionsList = []
        let usersList = await creditService.getUsers(1, searchKey)
        console.log('search business - okkkkkkkkkkkkkkk',usersList)
        if(usersList?.data?.length){
            usersList?.data.map((item)=>{
                optionsList.push({label:`${item.name} - ${item.mobile}`, value:item.id})
                return ''
            })
        }
        
        return optionsList
    }

    const loadOptions = (
        inputValue,
        callback
      ) => {
        setTimeout(async() => {
          callback(await filterList(inputValue));
        }, 1000);
    };
    
    const optionChangeHandler = (event) =>{
        //console.log("optionsIds ######", optionsIds)
        onTagChange(event)
    }

    return (
        <>
        <AsyncSelect 
        value={selectedVal}
        onChange={optionChangeHandler}
        loadOptions={loadOptions} 
        defaultOptions={getAllOptions()}
        />
        </>
    )
}

export default UserSelector