import {Modal, Button} from "react-bootstrap"
import NewAudienceForm from '../Audience/NewAudienceForm';

const NewAudience = (props) =>{
    const {show, popupHandler, selectedAudience, isEdit} =props
    
    const handleClose = () => {
        popupHandler(false)
    } 

  return (
    <>
      
      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <h3>Create Audience</h3>
        </Modal.Header>
        <Modal.Body>
            <NewAudienceForm 
            onNewAudienceSubmit={handleClose}
            selectedAudience={selectedAudience} 
            isEdit={isEdit}
             />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewAudience;