import {Table} from "react-bootstrap"
import Classes from "./style.module.css"
import PaginationList from "../Pagination";
import {ICONS} from "../../constants/icons"
import {format } from "date-fns"
import BlockContactsFilterForm from "./FilterForm"
import {deleteBlockContact} from "../../store/slices/blockContacts/delete"
import { useDispatch } from "react-redux";

const BlockContactListItems = (props) =>{
  
  const {setIsShow, 
    blockContacts, 
    setSelectedContact,
    setLoading,
    setFilterData,
    setPage, 
    page,
    scopeType
  } = props

  const dispatch = useDispatch()
  let paginationData = {};
  if (blockContacts) {
    paginationData = {
      total:blockContacts.total, 
      recordsPerPage:blockContacts.recordsPerPage,
      page:paginationData.page,
      template:`block-contacts/${scopeType}`
    };
  }

  const addNewHandler = (status) =>{
    try{
      
      setSelectedContact('')
      setIsShow(status)
    }catch(error){
      console.log(error)
    }
  }

  const editHandler = (item) =>{
    try{
      setSelectedContact(item)
      setIsShow(true)
    }catch(error){
      console.log(error)
    }
  }

  const __deleteHandler = (id) => {
    try{
      let confirmDelete = window.confirm("Are you sure to delete this?")
       
      if(!confirmDelete){
        console.log('sorry....revert')
        return 
      }
       
      dispatch(deleteBlockContact(id))
      
    }catch(error){
      console.log(error)
    }
  }

  const getBlockValue = (item) =>{
    try{
      
      if(item.blockType==='Email' || item.blockType==='Mobile'){
        return item.blockValue
      }else if(item.blockType==='Users Self' || item.blockType==='Users - All Business'){
        return item.business.name+`(${item?.business?.mobile})`
      }else if(item.blockType==='Defaulter Individual'){
        return item?.business?.buyer_trade_name+`(${item?.business?.buyer_gst_no})`
      }else{
        return item?.business?.trade_name+`(${item?.business?.gst_no})`
      }
    }catch(error){
      console.log(error)
    }
  }
  const getScopeValue = (item) =>{
    try{
      
      if(item.scopeType==='Tags'){
        return item.tag_name
      }else if(item.scopeType==='Campaign'){
        return item.campaign_name
      }else{
        return item.scopeValue
      }
    }catch(error){
      console.log(error)
    }
  }
    const _tableHeader = () => {
        return (
          <thead>
          <tr>
            <th>#</th>
            <th>Scope</th>
            <th>Scope Value</th>
            <th>Block Type</th>
            <th>Block Value</th>
            <th>Status</th>
            <th>Created On</th>
            <th>Action</th>
          </tr>
          </thead>
        )
      }
    
      const _listITems = () => {
        let pageOffset = +blockContacts?.recordsPerPage*(page-1)
        if(!blockContacts?.data.length){
            return (<tr><td colSpan={8}>No block contacts found!!</td></tr>)
        }
        return blockContacts.data.map((item, index)=>{
          return (
            
            <tr key={item.id}>
              <td>{pageOffset + (index + 1)}</td>
              <td>{item.scopeType}</td>
              <td>{getScopeValue(item)}</td>
              <td>{item.blockType}</td>
              <td>{getBlockValue(item)}</td>
              <td>{item.status===1?'Active':'Inactive'}</td>
              <td>{format(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
              <td className={Classes.actions}>
                <span><img src={ICONS.edit_action} alt="Edit" onClick={()=>editHandler(item)} /></span> 
                <span><img src={ICONS.delete_action} onClick={()=>__deleteHandler(item.id)} alt="Delete" /></span> 
              </td>
            </tr>
            
          )
        })
      }

    return (
        <>
        <BlockContactsFilterForm 
            onAddNewBlock={addNewHandler}
    setLoading={setLoading} 
    setFilterData={setFilterData} page={page} 
    setPage={setPage}  
    template={paginationData.template} 
    scopeType={scopeType}
    />
    <Table className="striped bordered  hover">
      {_tableHeader()}
      {blockContacts && <tbody>{_listITems()}</tbody>}
    </Table>
    {paginationData && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}

        </>
    )
}

export default BlockContactListItems