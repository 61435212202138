import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getBlockContacts = async (page, filterData, isAll) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { page, filterData, isAll };
    console.log("data block receipient", data);
    let result = await apiService.post(API_URL.GET_BLOCKCONTACTS, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getBlockContact = async (id) => {
  try {
    
    let token = getToken();

    let result = await apiService.get(API_URL.GET_BLOCKCONTACT+id, token);
    return result;
    
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const createBlockContact = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { ...payload };
    console.log("CREATE campaign tag data #okkkkkkk", data);
    let result = await apiService.post(API_URL.BLOCKCONTACT_CREATE, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const updateBlockContact = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { ...payload };
    console.log("data", data);
    let result = await apiService.put(API_URL.BLOCKCONTACT_UPDATE, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const deleteBlockContact = async (payload) => {
  try {
    
    let token = getToken();

    //if (gstn) {console
    console.log('delete contact endpoint ........', API_URL.GET_BLOCKCONTACT+payload)
    let result = await apiService.deleteData(API_URL.GET_BLOCKCONTACT+payload, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const blockContactsService = {
    getBlockContacts,
    getBlockContact,
    createBlockContact,
    updateBlockContact,
    deleteBlockContact,
    
};

export default blockContactsService;
