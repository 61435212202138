import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom"
import Layout from "../../layouts/default";
import { useSelector, useDispatch } from "react-redux";
import { getTemplates,cloneTemplate } from "../../store/slices/templates/templates";
import { Table} from "react-bootstrap";
import {format } from "date-fns"
import TemplateFilterForm from "../../components/Template/FilterForm"
import PaginationList from "../../components/Pagination";
import {ICONS} from "../../constants/icons"
import Classes  from "./style.module.css";
import TemplatePreview from "../../components/Template/TemplatePreview"

export const Templates = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState('');
  const { templates } = useSelector((state) => state.templatesSlice);
  const { reloadTemplates } = useSelector((state) => state.createTemplateSlice);
  const [showTestPop, setShowTestPop] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getTemplates({ page, filterData }));
  },[dispatch, loading, page, filterData,reloadTemplates])

  let paginationData = {};
  if (templates) {
    paginationData = {
      total:templates.total, 
      recordsPerPage:templates.recordsPerPage,
      page:paginationData.page,
      template:'templates'
    };
  }

  const _closePopHandle = () =>{
    try{
        setShowTestPop(false)
    }catch(error){
        console.log(error)
    }
  }

  const _previewHandler = async (id) =>{
    try{
      await __setPreviewTemplate(id)
        
        setShowTestPop(true) 
    }catch(error){
        console.log(error)
    }
  }

  const __setPreviewTemplate = async (id) =>{
    try{
      if(templates?.data.length){
        let template = templates?.data.filter((item)=>item.id===id)
        setSelectedTemplate(template.pop())
      }
       
    }catch(error){
      console.log(error)
    }
  }

  const __templateCloneHandler = (id) =>{
    try{
      
      let confirmClone = window.confirm("Are you sure to Clone this template?")
       
      if(!confirmClone){
        console.log('sorry....revert')
        return 
      }
       
      dispatch(cloneTemplate({id}))
      setTimeout(()=>{
        setLoading(!loading)
      },200)
    }catch(error){
      console.log(error)
    }
  }

  const _tableHeader = () => {
    return (
      <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Channel</th>
        <th>Status</th>
        <th>Created On</th>
        <th>Modified On</th>
        <th>Action</th>
      </tr>
      </thead>
    )
  }

  const _templateList = () => {
    let pageOffset = +templates?.recordsPerPage*(page-1)
    if(!templates?.data.length){
        return (<tr><td colSpan={8}>No template found!!</td></tr>)
    }
    return templates.data.map((item, index)=>{
      return (
        
        <tr key={item.id}>
          <td>{pageOffset + (index + 1)}</td>
          <td>{item.name}</td>
          <td>{item?.channel?.name?item?.channel?.name:''}</td>
          <td>{item.status}</td>
          <td>{format(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
          <td>{format(new Date(item.updatedAt), 'yyyy-MM-dd')}</td>
          <td className={Classes.actions}>
            <Link to={`/templates/edit/${item.id}`}><img src={ICONS.edit_action} alt="Edit" /></Link> 
            <span onClick={()=>_previewHandler(item.id)}><img src={ICONS.view_action} alt="View" /></span>
            <span style={{cursor:"pointer"}} onClick={()=>__templateCloneHandler(item.id)}>
              <img src={ICONS.clone_action} alt="Clone" />
            </span>
          </td>
        </tr>
        
      )
    })
  }
  
  return <Layout>
    <TemplateFilterForm 
    setLoading={setLoading} 
    setFilterData={setFilterData} page={page} 
    setPage={setPage}  
    template={paginationData.template}
    />
    <Table className="striped bordered  hover">
      {_tableHeader()}
      {templates && <tbody>{_templateList()}</tbody>}
    </Table>
    {paginationData && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}
    {selectedTemplate && <TemplatePreview 
        selectedTemplate={selectedTemplate}  
        showTestPop={showTestPop} 
        closeTestPop={_closePopHandle} 
    />  }
  </Layout>;
};