import {useEffect, useState} from "react"
import BlockContactFormEmail from "./FormEmail";
import BlockContactFormMobile from "./FormMobile";
import BlockContactFormBusiness from "./FormBusiness";
import BlockContactFormDefaulter from "./FormDefaulter";
import BlockContactFormUsers from "./FormUsers";

const BlockContactAddNew = (props) =>{
  const {scopeType,contact, onAddUpdate} = props
  const [blockType, setBlockType] = useState('')

  useEffect(()=>{
    setBlockType(contact?.blockType?contact?.blockType:'')
  },[])

    const getBlockOptions = () => {
        try{
            let blockTypes = [
                'Mobile', 
                'Email', 
                'Business',
                'Defaulter Individual',
                'All Defaulter of Supplier', 
                'Users Self',
                'Users - All Business'
            ]
            return blockTypes.map((blocktype)=><option key={blocktype} value={blocktype} label={blocktype}>{blocktype}</option>)
        }catch(error){
            console.log(error)
        }
      }

    const blockChangeHandler = (event) =>{
        setBlockType(event.target.value)
    }
    
    const mondalCloseHandler = () =>{
        onAddUpdate()
    }

    return(
            <>
            <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">Block Type</label>
                <div className="col-md-9">
                    {!contact ? <select
                        className="form-select"
                        name="blockType"
                        value={blockType}
                        onChange={blockChangeHandler}
                        style={{ display: "block" }}
                    >
                        <option value='' label="Select a Block type">Select a Block type{" "}</option>
                        {getBlockOptions()}
                    </select>:contact.blockType}
                </div>
                
            </div>
           {blockType && <div className="form-wrapper">
                {blockType==='Mobile' && <BlockContactFormMobile 
                blockType={blockType} 
                scopeType={scopeType} 
                contact={contact}
                onAddUpdate={mondalCloseHandler}
                />}    
                {blockType==='Email' && <BlockContactFormEmail 
                blockType={blockType} 
                scopeType={scopeType} 
                contact={contact} 
                onAddUpdate={mondalCloseHandler}
                /> }
                {blockType==='Business' && <BlockContactFormBusiness 
                blockType={blockType} 
                scopeType={scopeType} 
                contact={contact} 
                onAddUpdate={mondalCloseHandler}
                />}
                {blockType==='Defaulter Individual' && <BlockContactFormDefaulter 
                blockType={blockType} 
                scopeType={scopeType} 
                contact={contact} 
                onAddUpdate={mondalCloseHandler}
                />}
                {blockType==='All Defaulter of Supplier' && <BlockContactFormDefaulter 
                blockType={blockType} 
                scopeType={scopeType} 
                contact={contact} 
                onAddUpdate={mondalCloseHandler}
                />}
                {blockType==='Users Self' && <BlockContactFormUsers 
                blockType={blockType} 
                scopeType={scopeType} 
                contact={contact} 
                onAddUpdate={mondalCloseHandler}
                />}
                {blockType==='Users - All Business' && <BlockContactFormUsers 
                blockType={blockType} 
                scopeType={scopeType} 
                contact={contact} 
                onAddUpdate={mondalCloseHandler}
                />}
            </div>}
        </>
    )
}

export default BlockContactAddNew;