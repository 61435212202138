import React from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker'; // Assuming you're using react-datepicker
import 'react-datepicker/dist/react-datepicker.css';
import { createBrowserHistory } from 'history';

const ReportFilterForm = (props) => {
  const { setFilterData, setPage, template} = props;
  const { channels } = useSelector((state) => state.channelsSlice);
console.log("Our CHannellllll",channels)
  const initialValues = {
    name: "",
    status: "",
    channel: "",
    createdAt: null,
    createdTo: null,
  };
  const getChannelOptions = () => {
    try {
      if (channels && channels.data) {
        // Create a Set to track unique channel names
        const uniqueChannels = Array.from(new Set(channels.data.map(channel => channel.name)))
          .map(name => channels.data.find(channel => channel.name === name));
  
        return uniqueChannels.map((channel) => (
          <option key={channel.id} value={channel.id} label={channel.name}>
            {channel.name}
          </option>
        ));
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  

  const formik = useFormik({
    initialValues,
  
    onSubmit: (values) => {
      const filteredValues = { ...values };
  
      // Remove empty fields
      Object.keys(filteredValues).forEach((key) => {
        if (!filteredValues[key] && filteredValues[key] !== 0) {
          delete filteredValues[key];
        }
      });
  
      if (values.channel) {
        filteredValues.channel = { id: values.channel };
      }
  
      // Adjust the date filter if both dates are selected
      if (values.createdAt && values.createdTo) {
        const createdAtDate = new Date(values.createdAt);
        const createdToDate = new Date(values.createdTo);
  
        // Adjust createdAt to one day before
        createdAtDate.setDate(createdAtDate.getDate()+1);
        filteredValues.createdAt = createdAtDate.toISOString().split('.')[0];
  
        // Adjust createdTo to one day after
        createdToDate.setDate(createdToDate.getDate()+1);
        filteredValues.createdTo = createdToDate.toISOString().split('.')[0];
  
        console.log("Adjusted Filter Date (one day before):", filteredValues.createdAt);
        console.log("Adjusted Filter Date (next day):", filteredValues.createdTo);
      }
  
      setFilterData(filteredValues);
      setPage(1);
      createBrowserHistory().push(`/${template}?page=1`);
    },
  });
  

  const handleReset = () => {
    props.setLoading(false);
    props.setFilterData("");
    formik.resetForm();
  };

  // const handleExport = () => {
  //   exportdata();
  // };

  return (
   <form onSubmit={formik.handleSubmit}>
  <Row className="mb-4 align-items-end">
    <Col md={2}>
      <label htmlFor="name">Name</label>
      <input
        name="name"
        id="name"
        type="text"
        className="form-control"
        placeholder="Enter name"
        onChange={formik.handleChange}
        value={formik.values.name}
      />
    </Col>
    <Col md={2}>
      <label htmlFor="channel">Channel</label>
      <select
        className="form-select"
        name="channel"
        value={formik.values.channel}
        onChange={formik.handleChange}
      >
        <option value="" label="Select a Channel">Select a Channel</option>
        {getChannelOptions()}
      </select>
    </Col>
    <Col md={2}>
      <label htmlFor="status">Status</label>
      <select
        className="form-select"
        name="status"
        value={formik.values.status}
        onChange={formik.handleChange}
      >
        <option value="" label="Select Status">Select Status</option>
        <option value="draft" label="Draft">Draft</option>
        <option value="published" label="Published">Published</option>
      </select>
    </Col>
    <Col md={2}>
      <label htmlFor="createdAt">Created From</label>
      <DatePicker
        selected={formik.values.createdAt}
        onChange={(date) => formik.setFieldValue("createdAt", date)}
        className="form-control"
        placeholderText="Select create date"
      />
    </Col>
    <Col md={2}>
      <label htmlFor="createdTo">Created To</label>
      <DatePicker
        selected={formik.values.createdTo}
        onChange={(date) => formik.setFieldValue("createdTo", date)}
        className="form-control"
        placeholderText="Select end date"
      />
    </Col>
    <Col md={2} className="d-flex justify-content-start align-items-center">
      <Button as="input" type="submit" value="Filter" className="me-2" />{" "}
      <Button onClick={handleReset} type="reset" variant="secondary">
        Reset
      </Button>
    </Col>
  </Row>
</form>

  );
};

export default ReportFilterForm;
