import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import UsersService from "../../../services/users";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async ({ page, filterData }, thunkAPI) => {
    try {
      console.log('filter users params ............okkkkkk', filterData)
      const result = await UsersService.getUsers(
        page,
        filterData
      );
      
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { users: null };

const usersSlice = createSlice({
  name: "users",
  initialState,

  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      console.log("getUsers.fulfilled", action);
      state.users = action.payload;
      //return { ...state, defaulters: action.payload.data };
    },
    [getUsers.rejected]: (state, action) => {
      state.users = null;
      console.log("getUsers.rejected", action);
      console.log(action)
      //return { ...state, defaulters: [] };
    },
  },
});

export default usersSlice.reducer;
