import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import {getToken} from "../../helpers/common";

const getAudiences = async (page, filterData, isAll) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {page, filterData, isAll};
    console.log("data", data);
    let result = await apiService.post(API_URL.GET_AUDIENCES, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const getAudience = async (id) => {
  try {
    let token = getToken();
    console.log("getAudience#####id", id);
    let result = await apiService.get(API_URL.GET_AUDIENCE + id, token);
    return result;
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const createAudience = async (payload) => {
  try {
    let data = {};
    let result;
    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("CREATE campaign data #okkkkkkk", data);
    if (data.headerKeys) {
      result = await apiService.post(API_URL.GUST_AUDIENCE_CREATE, data, token);
    } else {
      result = await apiService.post(API_URL.AUDIENCE_CREATE, data, token);
    }

    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const updateAudience = async (payload) => {
  try {
    let data = {};
    let result;
    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("data@@@@@!!#$$$$$", data);
    if (data.hasGustAudience === "guest") {
      result = await apiService.put(API_URL.GUST_AUDIENCE_UPDATE, data, token);
    } else {
      result = await apiService.put(API_URL.AUDIENCE_UPDATE, data, token);
    }

    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const fetchAudiencesByQuery = async (
  sqlQuery,
  startLimit,
  endLimit,
  audienceId,
  hasGuestAudience
) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {sqlQuery, startLimit, endLimit, audienceId, hasGuestAudience};
    console.log("data", data);
    let result = await apiService.post(API_URL.GET_AUDIENCE_QUERY, data, token);
    return result;
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const audiencesService = {
  getAudiences,
  getAudience,
  createAudience,
  updateAudience,
  fetchAudiencesByQuery,
};

export default audiencesService;

export const getAudienceSelectedKeys = async (id) => {
  try {
    let token = getToken();
    console.log("getAudience#####id", id);
    let result = await apiService.get(API_URL.GET_AUDIENCE + id, token);
    return result;
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};
