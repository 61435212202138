import { useFormik } from 'formik';
import {Button} from "react-bootstrap"
import { useDispatch } from 'react-redux';
import {createBlockContact, updateBlockContact} from "../../store/slices/blockContacts/create"
import UserSelector from './UserSelector';
import { useState, useEffect } from 'react';
import campaignService from "../../services/campagins";
import CampaignSelector from './CampaignSelector';
import CampaignTagsSelector from "../CampaignTags/Selector"
import campaignTagsService from "../../services/campaignTags";

const getBlockValue = (contact) =>{
    let blockValue = ''
    if(contact?.id){
        blockValue = {label:contact.business.name+` - ${contact.business.mobile}`, value:contact.blockValue} 
    }
    return blockValue
}

const getBlockStatus = (contact) =>{
    let blockStatus = 1
    if(contact?.id){
        blockStatus = contact.status 
    }
    return blockStatus
}

const BlockContactFormUsers = (props) =>{
    const {contact, scopeType, blockType,onAddUpdate} = props
    const [selectedUser, setSelectedUser] = useState(getBlockValue(contact))
    const [isValid, setIsValid] = useState(true)
    const [status,setStatus] = useState(getBlockStatus(contact))
    const [selectedCampaign, setSelectedCampaign] = useState('')
    const [isCampaignValid, setIsCampaignValid] = useState(true)
    const [selectedTag, setSelectedTag] = useState('')
    const [isValidTag, setIsValidTag] = useState(true)
    const dispatch = useDispatch()

    const userChangeHandler = (event) =>{
        try{
            console.log('selected supplier ', event)
            setSelectedUser(event)
            setIsValid(true)
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        if(scopeType==='Campaign'){
          getCampaignInfo()
        }
        if(scopeType==='Tags'){
          getTagsInfo()
        }
        
      },[])
  
      const getCampaignInfo = async () =>{
        try{
          let blockValue = ''
          if(contact?.id){
              let filterData = {id:contact.scopeValue}
              let selectedResult = await campaignService.getCampaigns(1, filterData)
              console.log('Selected Result....',selectedResult, selectedResult.data[0].name)
              blockValue = {label:selectedResult.data[0].name, value:contact.scopeValue} 
              setSelectedCampaign(blockValue)
          }
          
        }catch(error){
          console.log()
        }
      }

      const getTagsInfo = async () =>{
        try{
          let blockValue = ''
          if(contact?.id){
              let selectedResult = await campaignTagsService.getCampaignTag(contact.scopeValue)
              blockValue = {label:selectedResult.data.name, value:contact.scopeValue} 
              setSelectedTag(blockValue)
          }
          
        }catch(error){
          console.log()
        }
      }

    const campaignChangeHandler = (event) =>{
        try{
            console.log('selected supplier ', event)
            setSelectedCampaign(event)
            setIsCampaignValid(true)
        }catch(error){
            console.log(error)
        }
    }

    const tagChangeHandler = (event) =>{
        try{
            console.log('selected tag ', event)
            setSelectedTag(event)
            setIsValidTag(true)
        }catch(error){
            console.log(error)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            scopeType:  scopeType,
            scopeValue:0,
            blockType:blockType,
            blockValue:selectedUser,
            status:status
        },
        
        onSubmit: (values, {resetForm}) => {
            
            console.log('submitted values.....', values)
            if(scopeType==='Campaign' && !selectedCampaign){
                setIsCampaignValid(false)
                return
            }
            if(scopeType==='Campaign' && selectedCampaign){
                values.scopeValue = selectedCampaign.value
            }

            //block contact by campaign tags
            if(scopeType==='Tags' && !selectedTag){
                setIsValidTag(false)
                return
            }
            if(scopeType==='Tags' && selectedTag){
                values.scopeValue = selectedTag.value
            }

            if(!selectedUser){
                setIsValid(false)
                return 
            }

            values.blockValue = selectedUser.value
            console.log('submitted values #########',values);
            if(contact?.id){
              values.id = contact.id
              dispatch(updateBlockContact(values));
            }else{
              dispatch(createBlockContact(values));
            }
            
            onAddUpdate()
            resetForm({values:''})
        },
   
      });
      return(
        <form onSubmit={formik.handleSubmit}>
            {scopeType==='Campaign' && <div className="form-group row mb-3">
            <label htmlFor="name" className="col-md-3 col-form-label">Campaign</label>
            <div className="col-md-9">
                <CampaignSelector 
                onTagChange={campaignChangeHandler}
                selectedVal={selectedCampaign}
                />
                {  !isCampaignValid ? (
            <p className="error text-danger">
                Please select a Campaign.
            </p>
            ) : null}
            </div>
        </div>}

        {scopeType==='Tags' && <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">Campaign Tag</label>
                <div className="col-md-9">
                   <CampaignTagsSelector 
                   onTagChange={tagChangeHandler}
                   selectedTags={selectedTag} 
                   multiOption={false} 
                   showLabel={false}
                   />
                    {  !isValidTag ? (
                <p className="error text-danger">
                  Please select a Tag.
                </p>
              ) : null}
                </div>
            </div>}
            
           <div className="form-group row mb-3">
                <label htmlFor="name" className="col-md-3 col-form-label">User</label>
                <div className="col-md-9">
                   <UserSelector 
                   onTagChange={userChangeHandler}
                   selectedVal={selectedUser}
                   />
                    {  !isValid ? (
                <p className="error text-danger">
                  Please select a User.
                </p>
              ) : null}
                </div>
            </div>
            <div className="form-group row mt-3">
                <label htmlFor="email" className="col-sm-3 col-form-label">Status</label>
                <div className="col-sm-9">
                    <div className="form-check form-check-inline">
                        <input 
                        className="form-check-input" 
                        type="radio" 
                        name="status" 
                        id="statusActive" 
                        onChange={(event)=>{
                            setStatus(event.currentTarget.value)
                        }}
                        value='1' 
                        checked={+status===1?true:false} />
                        <label className="form-check-label" htmlFor="statusActive">Active</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input 
                        className="form-check-input" 
                        type="radio" 
                        name="status" 
                        id="statusInctive" 
                        checked={+status===0?true:false}
                        value='0' 
                        onChange={(event)=>{
                            setStatus(event.currentTarget.value)
                        }} 
                        />
                        <label className="form-check-label" htmlFor="statusInctive">Inactive</label>
                    </div>
                </div>
                
            </div>

            <div id="btnWrapper" className='text-center mt-4'>
                <Button variant='primary' type="submit">Submit</Button>
            </div>
        </form>
    )
}

export default BlockContactFormUsers;