import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import TemplatesService from "../../../services/templates";

export const getTemplate = createAsyncThunk(
  "templates/getTemplate",
  async (id, thunkAPI) => {
    try {
      const result = await TemplatesService.getTemplate(id);
      console.log('getTemplate ######- ', result)
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { emailTemplate: {} };

const emailTemplateSlice = createSlice({
  name: "emailTemplate",
  initialState,

  extraReducers: {
    [getTemplate.fulfilled]: (state, action) => {
      console.log('getTemplate.fulfilled####...', action.payload)
      state.emailTemplate = action.payload;
    },
    [getTemplate.rejected]: (state, action) => {
      console.log('template load rejected details...', action.payload)
      //state.emailTemplate = null;
    },
  },
});

export default emailTemplateSlice.reducer;
