import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import usersService from "../../../services/users";
import {toast} from "react-toastify"

export const createUser = createAsyncThunk(
  "users/createUser",
  async (payload, thunkAPI) => {
    try {
        console.log('request payload....', payload)
      const result = await usersService.createUser(
        payload
      );
      console.log('reponse payload....', result)
      if (!result?.data?.error) {
        toast.success(result.data.message)
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (payload, thunkAPI) => {
    try {
        console.log('create user all thunk calling....', payload)
      const result = await usersService.updateUser(
        payload
      );
      if (!result?.data?.error) {
        toast.success(result.data.message)
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (payload, thunkAPI) => {
    try {
        console.log('create user all thunk calling....', payload)
      const result = await usersService.deleteUser(
        payload
      );
      if (!result?.data?.error) {
        toast.success(result.data.message)
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { 
  user: null,
  reloadUsers:null
};

const createUserSlice = createSlice({
  name: "user",
  initialState,

  extraReducers: {
    [createUser.fulfilled]: (state, action) => {
      console.log("createUser.fulfilled...", action.payload);
      state.user = action.payload;
      state.reloadUsers = !state.reloadUsers;
    },
    [createUser.rejected]: (state, action) => {
      state.user = null;
      console.log("createUser.rejected...", action.payload);
    },
    [updateUser.fulfilled]: (state, action) => {
      console.log("updateUser.fulfilled...", action.payload);
      state.user = action.payload;
      state.reloadUsers = !state.reloadUsers;
    },
    [updateUser.rejected]: (_, action) => {
      console.log("updateUser.rejected...", action.payload);
    },
    [deleteUser.fulfilled]: (state, action) => {
      console.log("deleteUser.fulfilled...", action.payload);
      state.reloadUsers = !state.reloadUsers;
    },
    [deleteUser.rejected]: (_, action) => {
      console.log("updateUser.rejected...", action.payload);
    },
  },
});

export default createUserSlice.reducer;
