import {Modal, Button} from "react-bootstrap";
import NewGustAudienceForm from "../Audience/NewGustAudienceForm";

const NewGustAudience = (props) => {
  const {show, popupHandler, selectedAudience, isEdit, chhanelName} = props;

  console.log("NewGustAudience#####", props);

  const handleClose = () => {
    popupHandler(false);
  };

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <h3>Create Gust Audience</h3>
        </Modal.Header>
        <Modal.Body>
          <NewGustAudienceForm
            onNewAudienceSubmit={handleClose}
            selectedAudience={selectedAudience}
            isEdit={isEdit}
            chhanelName={chhanelName}
            popupHandler={popupHandler}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewGustAudience;
