import { combineReducers } from "redux";
import authSlice from "./auth";
import messageSlice from "./message";
import campaignsSlice from "./campaigns/campaigns";
import sidebarSlice from "./sidebar";
import campaignSlice from "./campaigns/campaign";
import usersSlice from "./users/users";
import channelsSlice from "./channels/channels";
import createCampaignSlice,{audienceResultsSlice} from "./campaigns/createCampaign";
import audiencesSlice from "./audiences/audiences";
import createAudiencesSlice from "./audiences/createAudience";
import templatesSlice from "./templates/templates";
import createTemplateSlice from "./templates/createTemplate";
import createCampaignRecurrenceSlice from "./campaigns/createCampaignRecurrence";
import emailTemplateSlice from "./templates/template";
import audienceSlice from "./audiences/audience";
import audienceCampaignsSlice from "./audiences/audienceCampaigns";
import campaignErrorsSlice from "./campaigns/campaignErrors";
import createUserSlice from "./users/createUser";
import campaignTagsSlice from "./campaignTags/campaignTags";
import campaignTagSlice from "./campaignTags/campaignTag";
import createCampaignTagSlice from "./campaignTags/create";
import deleteCampaignTagsSlice from "./campaignTags/delete";
import blockContactsSlice from "./blockContacts/blockRecipients"
import blockContactSlice from "./blockContacts/blockRecipient"
import blockContactCreatetSlice from "./blockContacts/create"
import blockContactDeleteSlice from "./blockContacts/delete"
import businessessSlice from "./creditq/business"
import emailResponseSlice from "./reportCamp/reportCamp"


export default combineReducers({
  authSlice,
  messageSlice,
  campaignsSlice,
  sidebarSlice,
  campaignSlice,
  usersSlice,
  channelsSlice,
  createCampaignSlice,
  audiencesSlice,
  audienceResultsSlice,
  createAudiencesSlice,
  templatesSlice,
  createTemplateSlice,
  createCampaignRecurrenceSlice,
  emailTemplateSlice,
  audienceSlice,
  audienceCampaignsSlice,
  campaignErrorsSlice,
  createUserSlice,
  campaignTagsSlice,
  campaignTagSlice,
  createCampaignTagSlice,
  deleteCampaignTagsSlice,
  blockContactsSlice,
  blockContactSlice,
  blockContactCreatetSlice,
  blockContactDeleteSlice,
  businessessSlice,
  emailResponseSlice
});
