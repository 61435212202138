import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Button} from "react-bootstrap"
import { useDispatch } from 'react-redux';
import {createUser, updateUser} from "../../store/slices/users/createUser"
import { useEffect, useState } from 'react';

const UsersForm = (props) =>{
    const { onNewSubmit, user} = props
    const [status,setStatus] = useState('')
    const dispatch = useDispatch()
   const mobileReg = /^[6,7,8,9]\d{9}$/
    const userSchema = Yup.object().shape({
        name:Yup.string().required('Name is required field.'),
        mobile:Yup.string("Please enter valid mobile number.")
        .required('Mobile is required field.')
        .matches(mobileReg,'Please enter valid mobile number.'),
        email:Yup.string().email("Please enter valid email Id.").required('Email is required field.')
    });
    
    useEffect(()=>{
        if(!status){
            console.log('status value..............okkkkkkkkk', status, user?.status)
            setStatus(user?.status===0 || user?.status===1?user.status:1)
        }
    },[status,user])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: user?.name?user.name:"",
            mobile: user?.mobile?user.mobile:"",
            email: user?.email?user.email:"",
            role:1,
            status:user?.status?user.status:0,
            password:'',
            confirmPassword:''
        },
        validationSchema:userSchema,

        onSubmit: values => {
            values.status = status
            if(user?.name){
                if(!values.confirmPassword){
                    delete values.confirmPassword
                }
                if(!values.password){
                    delete values.password
                }
                values.id = user.id
                dispatch(updateUser(values))
            }else{
                dispatch(createUser(values))
            }
            
            onNewSubmit(true)
        },
   
      });

      
    return(
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group row">
                <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                <div className="col-sm-10">
                    <input type="text" className="form-control" 
                    id="name" 
                    name="name"
                    onChange={formik.handleChange} 
                    value={formik.values.name} 
                    />
                    {formik.touched.name &&
              formik.errors.name ? (
                <span className="error text-danger">
                  {formik.errors.name}
                </span>
              ) : null}
                </div>
                
            </div>
            <div className="row mt-4">
                <label htmlFor="mobile" className="col-sm-2 col-form-label">Mobile</label>
                <div className="col-sm-10">
                    <input  className="form-control" type="text" name="mobile" id="mobile"
                    onChange={formik.handleChange} 
                    value={formik.values.mobile} 
                    />
                    {formik.touched.mobile &&
              formik.errors.mobile ? (
                <span className="error text-danger">
                  {formik.errors.mobile}
                </span>
              ) : null}
                </div>
                
                
            </div>

            <div className="row mt-4">
                <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                <div className="col-sm-10">
                    <input  className="form-control" type="text" name="email" id="email"
                    onChange={formik.handleChange} 
                    value={formik.values.email} 
                    />
                    {formik.touched.email &&
                    formik.errors.email ? (
                <span className="error text-danger">
                  {formik.errors.email}
                </span>
              ) : null}
                </div>
            </div>
            {/* <div className="row mt-4">
                <label htmlFor="email" className="col-sm-2 col-form-label">Password</label>
                <div className="col-sm-10">
                    <input  
                    className="form-control" 
                    type="password" 
                    name="password" 
                    id="password"
                    onChange={formik.handleChange} 
                    value={formik.values.password} 
                    />
                    {formik.touched.password &&
                    formik.errors.password ? (
                <span className="error text-danger">
                  {formik.errors.password}
                </span>
              ) : null}
                </div>
            </div>
            <div className="row mt-4">
                <label htmlFor="email" className="col-sm-2 col-form-label">Confirm Password</label>
                <div className="col-sm-10">
                    <input  
                    className="form-control" 
                    type="password" 
                    name="confirmPassword" 
                    id="confirmPassword"
                    onChange={formik.handleChange} 
                    value={formik.values.confirmPassword} 
                    />
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                <span className="error text-danger">
                  {formik.errors.confirmPassword}
                </span>
              ) : null}
                </div>
            </div> */}
            <div className="form-group row mt-3">
                <label htmlFor="email" className="col-sm-2 col-form-label">Status</label>
                <div className="col-sm-10">
                    <div className="form-check form-check-inline">
                        <input 
                        className="form-check-input" 
                        type="radio" 
                        name="status" 
                        id="statusActive" 
                        onChange={(event)=>{
                            setStatus(event.currentTarget.value)
                        }}
                        value='1' 
                        checked={+status===1?true:false} />
                        <label className="form-check-label" htmlFor="statusActive">Active</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input 
                        className="form-check-input" 
                        type="radio" 
                        name="status" 
                        id="statusInctive" 
                        checked={+status===0?true:false}
                        value='0' 
                        onChange={(event)=>{
                            setStatus(event.currentTarget.value)
                        }} 
                        />
                        <label className="form-check-label" htmlFor="statusInctive">Inactive</label>
                    </div>
                </div>
                
            </div>
            
            <div id="btnWrapper" className='text-center mt-4'>
                <Button variant='primary' className="btn-lg" type="submit">Submit</Button>
            </div>
        </form>
    )
}

export default UsersForm;