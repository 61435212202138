import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import blockContactsService from "../../../services/blockContacts";
import {toast} from "react-toastify"

export const createBlockContact = createAsyncThunk(
  "blockContacts/createBlockContact",
  async (payload, thunkAPI) => {
    try {
        console.log('create block recipient thunk calling....')
      const result = await blockContactsService.createBlockContact(
        payload
      );
      if (!result?.data?.error) {

        toast.success("block recipient created successfully.")
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateBlockContact = createAsyncThunk(
  "blockContacts/updateBlockContact",
  async (payload , thunkAPI) => {
    try {
      console.log('updating block recipient data from thunk function ', payload)
      const result = await blockContactsService.updateBlockContact(
        payload
      );
      if (!result?.data?.error) {
        
        toast.success("Block contact updated successfully.")
        return result;

      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { 
  blockContact: null,
  refreshBlockContact:false
};

const blockContactCreatetSlice = createSlice({
  name: "blockContactcreate",
  initialState,
  reducers:{
    reSetBlockContact(state, _){
      state.blockContact = null 
    }
  },
  extraReducers: {
    [createBlockContact.fulfilled]: (state, action) => {
      console.log("createBlockContact.fulfilled...", action.payload);
      state.blockContact=action.payload;
      state.refreshBlockContact = !state.refreshBlockContact;
    },
    [createBlockContact.rejected]: (state, action) => {
      state.blockContact = null;
    },
    [updateBlockContact.fulfilled]: (state, action) => {
      console.log("updateBlockContact.fulfilled okkkkkkkkkk", action);
      state.blockContact=action.payload;
      state.refreshBlockContact = !state.refreshBlockContact;
    },
    [updateBlockContact.rejected]: (_, action) => {
      //state.campaign = null;
      console.log("updateBlockContact.rejected okkkkkkkkkk", action);
    }
  },
});

export default blockContactCreatetSlice.reducer;
