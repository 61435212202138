import { useEffect, useState } from "react";
import AsyncSelect  from "react-select/async"
import campaignService from "../../services/campagins";

const CampaignSelector = (props) =>{
    const {onTagChange, selectedVal} = props
    const [campaigns, setCampaigns]=useState([])
    
    useEffect(()=>{
        getCampaigns()
    },[]) 
    
    const getCampaigns = async () =>{
        let results = await campaignService.getCampaigns(1, '')
        console.log('Campaign Selectes okkkkkkkkkk ',selectedVal)
        setCampaigns(results)
    }

    const getAllOptions = () =>{
        
        let optionsList = []
        if(campaigns?.data?.length){
            campaigns.data.map((item)=>{
                optionsList.push({label:`${item.name}`, value:item.id})
                return ''
            })
        }
        
        return optionsList
    }

    const filterList = async (searchKey) =>{
        let optionsList = []
        let filterData = {name:searchKey}
        let campaignList = await campaignService.getCampaigns(1, filterData)
        console.log('search Camapign - okkkkkkkkkkkkkkk',campaignList)
        if(campaignList?.data?.length){
            campaignList?.data.map((item)=>{
                optionsList.push({label:`${item.name}`, value:item.id})
                return ''
            })
        }
        
        return optionsList
    }

    const loadOptions = (
        inputValue,
        callback
      ) => {
        setTimeout(async() => {
          callback(await filterList(inputValue));
        }, 1000);
    };
    
    const optionChangeHandler = (event) =>{
        //console.log("optionsIds ######", optionsIds)
        onTagChange(event)
    }

    return (
        <>
        <AsyncSelect 
        value={selectedVal}
        onChange={optionChangeHandler}
        loadOptions={loadOptions} 
        defaultOptions={getAllOptions()}
        />
        </>
    )
}

export default CampaignSelector