import {Row, Col} from "react-bootstrap"
import {format } from "date-fns"
const UsersDetail = (props) =>{
    const {user} =props
    console.log('user details....###',user)
    return( 
        <div>
            <Row className="pb-3">
                <Col sm={4}>Role</Col>
                <Col sm={8}>{user.role.name}</Col>
            </Row>
            <Row className="pb-3">
                <Col sm={4}>Name</Col>
                <Col sm={8}>{user.name}</Col>
            </Row>
            <Row className="pb-3">
                <Col sm={4}>Mobile</Col>
                <Col sm={8}>{user.mobile}</Col>
            </Row>
            <Row className="pb-3">
                <Col sm={4}>Email</Col>
                <Col sm={8}>{user.email}</Col>
            </Row>
            <Row className="pb-3">
                <Col sm={4}>Created On</Col>
                <Col sm={8}>{format(new Date(user.createdAt), 'yyyy-MM-dd')}</Col>
            </Row>
            <Row>
                <Col sm={4}>Status</Col>
                <Col sm={8}>{user.status?'Active':'Inactive'}</Col>
            </Row>
            
        </div>
    )
}

export default UsersDetail;