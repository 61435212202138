import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom"
import Layout from "../../layouts/default";
import { useSelector, useDispatch } from "react-redux";
import { getCampaigns, deleteCampaign, cloneCampaign } from "../../store/slices/campaigns/campaigns";
import { Table } from "react-bootstrap";
import {format } from "date-fns"
import FilterForm from "../../components/Filter"
import PaginationList from "../../components/Pagination";
import {ICONS} from "../../constants/icons"
import Classes  from "./style.module.css";
import { createCampaignActions } from "../../store/slices/campaigns/createCampaign";
import { createTemplateActions } from "../../store/slices/templates/createTemplate";

export const Campaigns = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState('');
  const { campaigns } = useSelector((state) => state.campaignsSlice);
  const dispatch = useDispatch()

  useEffect(()=>{
    
    dispatch(getCampaigns({ page, filterData }));
  },[dispatch, loading, page, filterData])

  useEffect(()=>{
    dispatch(createCampaignActions.reSetCampaign())
    dispatch(createTemplateActions.reSetTemplate())
  },[])

  let paginationData = {};
  if (campaigns) {
    paginationData = {
      total:campaigns.total, 
      recordsPerPage:campaigns.recordsPerPage,
      page:paginationData.page,
      template:'campaigns'
    };
  }

  const __campaignDeleteHandler = (id) =>{
    try{
      
      let confirmDelete = window.confirm("Are you sure to delete this campaign?")
       
      if(!confirmDelete){
        console.log('sorry....revert')
        return 
      }
       
      dispatch(deleteCampaign({id}))
      setTimeout(()=>{
        setLoading(!loading)
      },200)
    }catch(error){
      console.log(error)
    }
  }
  const __campaignCloneHandler = (id) =>{
    try{
      
      let confirmClone = window.confirm("Are you sure to Clone this campaign?")
       
      if(!confirmClone){
        console.log('sorry....revert')
        return 
      }
       
      dispatch(cloneCampaign({id}))
      setTimeout(()=>{
        setLoading(!loading)
      },200)
    }catch(error){
      console.log(error)
    }
  }

  const _tableHeader = () => {
    return (
      <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Start Date</th>
        <th>Repeat</th>
        <th>Channel</th>
        <th>Status</th>
        <th>Created On</th>
        <th>Modified On</th>
        <th>Actions</th>
      </tr>
      </thead>
    )
  }

  const _itemsList = () => {
    let pageOffset = +campaigns?.recordsPerPage*(page-1)
    if(!campaigns?.data.length){
      return <tr><td colSpan="7">No Campaign found!!</td></tr>
    }
    return campaigns.data.map((item, index)=>{
        
      return (
        
        <tr key={item.id}>
          <td>{pageOffset + (index + 1)}</td>
          <td>{item.name}</td>
          <td>{format(new Date(item.scheduledDateTime), 'yyyy-MM-dd HH:mm')}</td>
          <td>{item.isRepeat?'Yes':'No'}</td>
          <td>{item?.channel?.name?item.channel.name:''}</td>
          <td>
            {item.status}
          </td>
          <td>{format(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
          <td>{format(new Date(item.updatedAt), 'yyyy-MM-dd')}</td>
          <td className={Classes.actions}><Link to={`/campaigns/edit/campaign/${item.id}`}>
            <img src={ICONS.edit_action} alt="Edit" /></Link> 
            <span style={{cursor:"pointer"}} onClick={()=>__campaignDeleteHandler(item.id)}>
              <img src={ICONS.delete_action} alt="Delete" />
            </span>
            <span style={{cursor:"pointer"}} onClick={()=>__campaignCloneHandler(item.id)}>
              <img src={ICONS.clone_action} alt="Clone" />
            </span>
          </td>
        </tr>
        
      )
    })
  }
  
  return <Layout>
    <FilterForm 
    setLoading={setLoading} 
    setFilterData={setFilterData} page={page} 
    setPage={setPage} 
    template={paginationData.template}
    />
    
    <Table className="striped bordered  hover">
      {_tableHeader()}
      {campaigns && <tbody>{_itemsList()}</tbody>}
    </Table>
    {paginationData && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}
  </Layout>;
};