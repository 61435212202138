import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import campaginsEmailRes from "../../../services/campReport/index";

// AsyncThunk for fetching email responses by page with filters
export const getCampEmailResponse = createAsyncThunk(
  "campagins/report",
  async ({ page, filterData }, thunkAPI) => {
    try {
      const result = await campaginsEmailRes.getEmailResponse(page, filterData);
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// AsyncThunk for fetching email responses by ID
export const getCampEmailResponseByID = createAsyncThunk(
  "campagins/reportById",
  async (id, thunkAPI) => {
    try {
      const result = await campaginsEmailRes.getEmailResponseByID(id);
      if (result) {
        if(result.length>0){
          return result;
        }
        else{
          return []
        }
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// AsyncThunk for fetching email responses based on filters (using POST)
export const getCampEmailResponseByFilter = createAsyncThunk(
  "campagins/reportByFilter",
  async (filterData, thunkAPI) => {
    try {
      const result = await campaginsEmailRes.getEmailResponseByFilter(filterData);
      if (result) {
        if(result.length>0){
          return result;
        }
        else{
          return []
        } 
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Updated initial state
const initialState = {
  campaignreports: null,
  emailResponseByID: null,
  emailResponseByFilter: null, // New state for filter results
};

// Slice definition
const emailResponseSlice = createSlice({
  name: "campaignreports",
  initialState,
  extraReducers: {
    // Handling getCampEmailResponse (by page and filters)
    [getCampEmailResponse.fulfilled]: (state, action) => {
      state.campaignreports = action.payload;
    },
    [getCampEmailResponse.rejected]: (state) => {
      state.campaignreports = null;
    },
    // Handling getCampEmailResponseByID (by ID)
    [getCampEmailResponseByID.fulfilled]: (state, action) => {
      state.emailResponseByID = action.payload;
    },
    [getCampEmailResponseByID.rejected]: (state) => {
      state.emailResponseByID = null;
    },
    // Handling getCampEmailResponseByFilter (by filterData)
    [getCampEmailResponseByFilter.fulfilled]: (state, action) => {
      state.emailResponseByID = action.payload;
    },
    [getCampEmailResponseByFilter.rejected]: (state) => {
      state.emailResponseByID = null;
    },
  },
});

export default emailResponseSlice.reducer;
