import { Fragment } from "react"
import {Table} from "react-bootstrap"

const SqlQueryResult = (props) =>{
    const {queryResults} = props
    
    const _tableHeader = () => {
        console.log('query result .......',queryResults)
        let firstRowData = queryResults?.data ? queryResults?.data[0]:[];
        let headerTitles = firstRowData? Object.keys(firstRowData):[]
        console.log('first row data....######', headerTitles)
        return headerTitles.map((item)=>{
            return (
                <th key={item+`_head`}>{item}</th>
              )
        })
      }

    const _renderTableData = (item) =>{
        try{
            
            let fieldNames = Object.keys(item)
            return fieldNames.map((fieldName)=>{
                return(<td key={fieldName+`${item.id}`}>{item[fieldName]}</td>)
            })
           
        }catch(error){
            console.log(error)
        }
    }
    const _getAudiences =  () =>{
        try{
            
            return queryResults.data.map((item,index)=>{
                return (
                  <tr key={'aud_'+index}>
                    {_renderTableData(item)}
                  </tr>
                )
              })
        }catch(error){
            console.log(error)
        }
    }

    const _resultSummary = () => {
        try{
            return (
                <div>
                    <p><span style={{fontWeight:'bold'}}>Total Audiences:</span>{queryResults.total}</p>
                    {queryResults?.total>0 && <p className="pb-1 mb-1" style={{fontWeight:'bold'}}>First 5 records:</p>}
                </div>
            )
        }catch(error){
            console.log(error)
        }
    }
    return (
        <Fragment>
            {_resultSummary()}
            <div style={{overflowX: "auto"}}>
                <Table className="striped  hover">
                    <thead><tr>{_tableHeader()}</tr></thead>
                    <tbody>{_getAudiences()}</tbody>
                </Table>
            </div>
            
        </Fragment>
    )
}

export default SqlQueryResult