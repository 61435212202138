import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getAudienceCampaigns} from "../../store/slices/audiences/audienceCampaigns"
import {CampaignList} from "../../components/Campaigns/CampaignList"

const CampaignsUsingThisAudience = (props) =>{
    const {audienceId} = props
    const {audienceCampaigns} = useSelector((state)=> state.audienceCampaignsSlice)
    const dispatch = useDispatch()
    useEffect(()=>{
        let payload = {
            filterData:{
                audience:{id:audienceId}
            },
            page:1
        }
        
        dispatch(getAudienceCampaigns(payload))
        
    },[])

    return (
        <div>
            {audienceCampaigns &&<h4>This audience is using for below Campaigns.</h4>}
            {audienceCampaigns && <CampaignList campaigns={audienceCampaigns} displayPagination={false} />}
        </div>
    )
}

export default CampaignsUsingThisAudience;