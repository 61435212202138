import {useState} from "react"
import { Table } from "react-bootstrap";
import {format } from "date-fns"
import PaginationList from "../Pagination";
import Classes  from "./style.module.css";

export const CampaignList = (props) => {
  const {campaigns, displayPagination} = props
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  
  let paginationData = {};
  if (campaigns) {
    paginationData = {
      total:campaigns.total, 
      recordsPerPage:campaigns.recordsPerPage,
      page:paginationData.page,
      template:'campaigns'
    };
  }

  const _tableHeader = () => {
    return (
      <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Start Date</th>
        <th>Repeat</th>
        <th>Channel</th>
        <th>Status</th>
        <th>Created On</th>
        <th>Modified On</th>
      </tr>
      </thead>
    )
  }

  const _itemsList = () => {
    let pageOffset = +campaigns?.recordsPerPage*(page-1)
    if(!campaigns?.data.length){
      return <tr><td colSpan="7">No Campaign found!!</td></tr>
    }
    return campaigns.data.map((item, index)=>{
        
      return (
        
        <tr key={item.id}>
          <td>{pageOffset + (index + 1)}</td>
          <td>{item.name}</td>
          <td>{format(new Date(item.scheduledDateTime), 'yyyy-MM-dd HH:mm')}</td>
          <td>{item.isRepeat?'Yes':'No'}</td>
          <td>{item?.channel?.name?item.channel.name:''}</td>
          <td>
            {item.status}
          </td>
          <td>{format(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
          <td>{format(new Date(item.updatedAt), 'yyyy-MM-dd')}</td>
          
        </tr>
        
      )
    })
  }
  
  return (
    <div>
    <Table className="striped bordered  hover">
      {_tableHeader()}
      {campaigns && <tbody>{_itemsList()}</tbody>}
    </Table>
    {paginationData && displayPagination && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}
    </div>
  )
    
};