import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getEmailResponse = async (page, filterData) => {
  try {
    let token = getToken();
    let result = await apiService.get(API_URL.GET_CAMPAIGN_EMAIL_RESPONSE, token, { params: { page, ...filterData } });
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getEmailResponseByID = async (id) => {
  try {
    console.log("RESULT by ID is",id)
    let token = getToken();
    let result = await apiService.get(`${API_URL.GET_CAMPAIGN_EMAIL_RESPONSE}/${id}`, token);
    console.log("MY RESULt",result)
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getEmailResponseByFilter = async (filterData) => {
  try {
    let token = getToken();

    // Make a POST request with filterData in the body
    let result = await apiService.post(
      `${API_URL.GET_CAMPAIGN_EMAIL_RESPONSE}/filter`, // Correct endpoint
      filterData, // Send filterData in the body
      token
    );

    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const campaignsEmailRes = {
  getEmailResponse,
  getEmailResponseByID,
  getEmailResponseByFilter
};

export default campaignsEmailRes;
