import { format } from 'date-fns'
import moment from "moment"
import { useEffect } from 'react'
import {weekNameOfDay, getWeekDayIndexByName} from "../../services/utils"

const ScheduleRepeatOnMonth = (props) =>{
    const {campaign, setMonthDay, monthDay} = props
    
    const getScheduleMonthlyOption = () =>{
        try{
            let options = []
            moment.locale('en-in');
            const dayOfMonth = moment(campaign.data.scheduledDateTime).format('DD')
            
            if(dayOfMonth){
                options.push(<option key="month" value={dayOfMonth}>Monthly on day {dayOfMonth}</option>)
            }
            
            let weeks = weekNameOfDay(campaign.data.scheduledDateTime)
           
            let weekDayName = moment(campaign.data.scheduledDateTime).format('dddd')
            let weekDayIndex = getWeekDayIndexByName(weekDayName)
            if(weekDayName){
                options.push(<option key="monthweek" value={`${weeks.value}:${weekDayIndex}`}>Monthly on the {weeks.label} {weekDayName}</option>)
            }
             
            return options
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        if(!monthDay){
            const dayOfMonth = moment(campaign.data.scheduledDateTime).format('DD')
            setMonthDay(dayOfMonth)
        }
    },[])

    return (
        <div className='mt-4'>
            <select className='form-select' name="month" value={monthDay} onChange={(e)=>setMonthDay(e.currentTarget.value)} >
                {getScheduleMonthlyOption()}
            </select>
           
        </div>
    )
}

export default ScheduleRepeatOnMonth;