import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom"
import Layout from "../../layouts/default";
import { useSelector, useDispatch } from "react-redux";
import { getCampaignErrors } from "../../store/slices/campaigns/campaignErrors";
import { Table } from "react-bootstrap";
import {format } from "date-fns"
import PaginationList from "../../components/Pagination";
import Classes  from "./style.module.css";

const CampaignErrors = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { campaignErrors } = useSelector((state) => state.campaignErrorsSlice);
  const dispatch = useDispatch()

  useEffect(()=>{
    
    dispatch(getCampaignErrors({ page, filterData:'' }));
    console.log('loading errors..... ')
  },[loading, page, dispatch])

  
  let paginationData = {};
  if (campaignErrors) {
    console.log('loading errors.....pagination ', campaignErrors)
    paginationData = {
      total:campaignErrors.total, 
      recordsPerPage:campaignErrors.recordsPerPage,
      page:campaignErrors.page,
      template:'campaignErrors'
    };
  }


  const _tableHeader = () => {
    return (
      <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Error Reason</th>
        <th>Type</th>
        <th>Created On</th>
        <th>Actions</th>
      </tr>
      </thead>
    )
  }

  const _itemsList = () => {
    let pageOffset = +campaignErrors?.recordsPerPage*(page-1)
    if(!campaignErrors?.data.length){
      return <tr><td colSpan="7">No Error found!!</td></tr>
    }
    return campaignErrors.data.map((item, index)=>{
        
      return (
        
        <tr key={item.id}>
          <td>{pageOffset + (index + 1)}</td>
          <td>{item.campaign.name}</td>
          <td>{item.errorReason}</td>
          <td>{item.errorType}</td>
          <td>{format(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
          <td className={Classes.actions}>
            <Link to={`/campaigns/edit/campaign/${item.campaign.id}`}>Fix</Link> 
            
          </td>
        </tr>
        
      )
    })
  }
  
  return <Layout>
    
    <Table className="striped bordered  hover">
      {_tableHeader()}
      {campaignErrors && <tbody>{_itemsList()}</tbody>}
    </Table>
    {paginationData && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}
  </Layout>;
};

export default CampaignErrors;