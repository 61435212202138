import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import campaignTagsService from "../../../services/campaignTags";
import {toast} from "react-toastify"

export const getCampaignTag = createAsyncThunk(
  "campaignTags/getCampaignTag",
  async (id, thunkAPI) => {
    try {
        console.log('Tags load filter in thunk .....', id)
      const result = await campaignTagsService.getCampaignTag( id );
      console.log('get tags all thunk calling....', result)
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { campaignTag: null };

const campaignTagSlice = createSlice({
  name: "campaignTag",
  initialState,

  extraReducers: {
    [getCampaignTag.fulfilled]: (state, action) => {
      console.log("campaignTag.fulfilled...", action.payload);
      state.campaignTag = action.payload;
    },
    [getCampaignTag.rejected]: (state, action) => {
      state.campaignTag = null;
    },
  },
});

export default campaignTagSlice.reducer;
