import {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button} from "react-bootstrap";
import Classes from "../Campaigns/style.module.css";
import {toast} from "react-toastify";
import {useSelector, useDispatch} from "react-redux";
import {audienceActions} from "../../store/slices/audiences/audience";
import CampaignsUsingThisAudience from "../../components/Audience/CampaignsUsingThisAudience";
import {
  createAudience,
  updateAudience,
} from "../../store/slices/audiences/createAudience";
import Papa from "papaparse";

const NewGustAudience = (props) => {
  const {selectedAudience, isEdit, onNewAudienceSubmit, chhanelName} = props;
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [gustAudienceData, setGustAudienceData] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const fileReader = new FileReader();
  const dispatch = useDispatch();
  const previewAudiences = useSelector(
    (state) => state.audienceResultsSlice.audiences
  );
  const {audienceCreated} = useSelector((state) => state.createAudiencesSlice);

  console.log("props####", props);

  const handleOnChange = (e) => {
    const files = e.target.files;
    console.log(files);
    setFile(files);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      console.log(file[0]);
      Papa.parse(file[0], {
        complete: function (results) {
          console.log("Finished:", results.data);
          csvFileToArray(results.data);
        },
      });
    }
  };

  const csvFileToArray = (data) => {
    const array = [];
    const csvHeader = data[0];
    // console.log("csvHeader####", csvHeader);
    for (let i = 1; i < data.length; i++) {
      console.log("csvRows", data[i]);
      let csvRows = data[i];
      let object = {};
      if (csvRows.length > 1) {
        for (let j = 0; j < csvHeader.length; j++) {
          object[csvHeader[j].replaceAll('"', "").replaceAll(" ", "_")] =
            csvRows[j].replaceAll('"', "").trim();
        }
        console.log("object#####", object);
        array.push(object);
      }
    }
    console.log("array#####", array);
    setGustAudienceData(array);

    if (array.length > 10) {
      let newArray = [];
      for (let i = 0; i < 10; i++) {
        newArray.push(array[i]);
      }
      setArray(newArray);
    } else {
      console.log("array#####", array);
      setArray(array);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));
  console.log("headerKeys#####", headerKeys);

  const audienceSchema = Yup.object().shape({
    name: Yup.string().required("Name is required field"),
  });

  console.log("selected audience...edit", isEdit, selectedAudience);
  const initialName = isEdit ? selectedAudience?.name : "";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: initialName,
    },
    validationSchema: audienceSchema,

    onSubmit: (values) => {
      console.log("values######", values);
      console.log("gustAudienceData#####", gustAudienceData);
      if (gustAudienceData.length > 0) {
        let data = {
          name: values,
          gustAudienceData: gustAudienceData,
          headerKeys: headerKeys,
        };
        const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
        for (let i = 0; i < headerKeys.length; i++) {
          if (specialChars.test(headerKeys[i])) {
            console.log("headerKeys[i])", headerKeys[i]);
            toast.error("In this CSV Header keys contains special characters");
            return;
          }
          if (headerKeys[i])
            if (chhanelName === "sms" || chhanelName === "whatsapp") {
              if (headerKeys[i].toLowerCase().match("mobile")) {
                gustAudience(data);
                return;
              }
            } else {
              if (headerKeys[i].toLowerCase().match(`${chhanelName}`)) {
                gustAudience(data);
                return;
              }
            }
        }
        toast.error(
          "First row of CSV must have mobile and email column heading."
        );
      } else {
        toast.error("Ops, something went wrong.");
      }
    },
  });

  const gustAudience = async (data) => {
    if (isEdit) {
      data.id = selectedAudience?.id;
      data.hasGustAudience = "guest";
      dispatch(updateAudience(data));
    } else {
      dispatch(createAudience(data));
    }

    setIsSubmited(true);
  };
  useEffect(() => {
    if (audienceCreated && isSubmited) {
      if (selectedAudience?.id) {
        dispatch(audienceActions.resetAudience());
      }

      onNewAudienceSubmit();
    }
  }, [audienceCreated, isSubmited, onNewAudienceSubmit]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-2 col-form-label">
          Name
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <span className="error text-danger">{formik.errors.name}</span>
          ) : null}
        </div>
      </div>
      <div style={{marginTop: "2%"}}>
        <label className="form-label">Select CSV file to upload</label>
        <div className="col-sm-10">
          <div style={{textAlign: "center"}}>
            <div style={{position: "relative"}}>
              <input
                className="form-control"
                type={"file"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              {/* <input
                type="file"
                accept=".csv,.xlsx,.xls"
                onChange={(e) => {
                  const files = e.target.files;
                  console.log(files);
                  if (files) {
                    console.log(files[0]);
                    Papa.parse(files[0], {
                      complete: function (results) {
                        console.log("Finished:", results.data);
                      },
                    });
                  }
                }}
              /> */}
              <div style={{position: "absolute", marginTop: "2%"}}>
                <button
                  onClick={(e) => {
                    handleOnSubmit(e);
                  }}
                >
                  IMPORT CSV
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: "5%"}}>
          {isEdit && (
            <CampaignsUsingThisAudience audienceId={selectedAudience?.value} />
          )}
        </div>

        {/* {array.length > 0 && (
         
        )} */}
        {array.length > 0 && (
          <div className={Classes.gustAudienceStyle}>
            <div>
              <h3>Total Records : {gustAudienceData.length}</h3>
            </div>
            <div className={Classes.showGustAudienceData}>
              <table>
                <thead>
                  <tr key={"header"}>
                    {headerKeys.map((key) => (
                      <th style={{paddingLeft: "20px"}}>{key}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {array.map((item) => (
                    <tr key={item.id}>
                      {Object.values(item).map((val) => (
                        <td style={{paddingLeft: "20px"}}>{val}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div
        id="btnWrapper"
        style={{marginTop: "6%"}}
        className="text-center mt-10"
      >
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default NewGustAudience;
