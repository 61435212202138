import { Route, Routes } from "react-router-dom";
import RequireAuth from "../components/RequireAuth";
import Login from "../pages/Auth/Login";
import Home from "../pages/Home";
import Campaigns from "../pages/Campaigns";
import Users from "../pages/Users";
import CampaignTemplates from "../pages/Templates"
import Audiences from "../pages/Audiences";
import NewCampaign from "../pages/Campaigns/NewCampaign"
import SetCampaignName from "../pages/Campaigns/SetCampaignName"
import SetCampaignAudience from "../pages/Campaigns/SetCampaignAudience"
import SetCampaignTemplate from "../pages/Campaigns/SetCampaignTemplate"
import CreateNewTemplate from "../pages/Campaigns/CreateNewTemplate"
import SetCampaignSchedule from "../pages/Campaigns/SetCampaignSchedule"
import CampaignEditTemplate from "../pages/Campaigns/EditTemplate"
import CampaignOthers from "../pages/Campaigns/SetOthers"
import CampaignErrors from "../pages/Campaigns/CampaignErrors"
import NewTemplate from "../pages/Templates/new"
import EditTemplate from "../pages/Templates/edit"
import BlockContactsByGlobal from "../pages/BlockContacts/BlockContactsByGlobal";
import BlockContactsByCampaign from "../pages/BlockContacts/BlockContactsByCampaign";
import BlockContactsByTags from "../pages/BlockContacts/BlockContactsByTags";
import Report from "../components/Report/Report";
import CampaignReportPage from "../components/Report/CampaignReportPage";

export function RenderRoutes({ routes }) {
  return (
    <Routes>
      <Route
        key={"ROOT"}
        path={"/"}
        exact={true}
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />

      <Route key={"LOGIN"} path={"/login"} exact={true} element={<Login />} />

      <Route
        key={"APP"}
        path={"/app"}
        exact={false}
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        key={"CAMPAIGNS"}
        path={"/campaigns"}
        exact={true}
        element={
          <RequireAuth>
            <Campaigns />
          </RequireAuth>
        }
      />

      <Route
        key={"CAMPAIGNSNEW"}
        path={"/campaigns/new"}
        exact={false}
        element={
          <RequireAuth>
            <NewCampaign />
          </RequireAuth>
        }
      />
      <Route
        key={"CAMPAIGNSNEW"}
        path={"/campaigns/new/campaign"}
        exact={false}
        element={
          <RequireAuth>
            <SetCampaignName />
          </RequireAuth>
        }
      />
      <Route
        key={"CAMPAIGNSNEW"}
        path={"/campaigns/edit/campaign/:id"}
        exact={false}
        element={
          <RequireAuth>
            <SetCampaignName />
          </RequireAuth>
        }
      />
      <Route
        key={"CAMPAIGNSNEWAUDIENCE"}
        path={"/campaigns/edit/audience/:id"}
        exact={false}
        element={
          <RequireAuth>
            <SetCampaignAudience />
          </RequireAuth>
        }
      />
      <Route
        key={"CAMPAIGNSNEWTEMPLATE"}
        path={"/campaigns/edit/template/:id"}
        exact={false}
        element={
          <RequireAuth>
            <SetCampaignTemplate />
          </RequireAuth>
        }
      />

  <Route
        key={"CAMPAIGNSUPDATETEMPLATE"}
        path={"/campaigns/edit/update-template/:id/:templateId"}
        exact={false}
        element={
          <RequireAuth>
            <CampaignEditTemplate />
          </RequireAuth>
        }
      />

      <Route
        key={"CAMPAIGNSNEWTEMPLATENEW"}
        path={"/campaigns/edit/createtemplate/:id"}
        exact={false}
        element={
          <RequireAuth>
            <CreateNewTemplate />
          </RequireAuth>
        }
      />
      <Route
        key={"CAMPAIGNSNEWSCHEDULR"}
        path={"/campaigns/edit/schedule/:id"}
        exact={false}
        element={
          <RequireAuth>
            <SetCampaignSchedule />
          </RequireAuth>
        }
      />
      <Route
        key={"CAMPAIGNSOTHERS"}
        path={"/campaigns/edit/others/:id"}
        exact={false}
        element={
          <RequireAuth>
            <CampaignOthers />
          </RequireAuth>
        }
      />

    <Route
        key={"CAMPAIGNSERRORS"}
        path={"/campaigns/errors"}
        exact={false}
        element={
          <RequireAuth>
            <CampaignErrors />
          </RequireAuth>
        }
      />
    <Route
        key={"TEMPLATESNEW"}
        path={"/templates/new"}
        exact={false}
        element={
          <RequireAuth>
            <NewTemplate />
          </RequireAuth>
        }
      />

    <Route
        key={"TEMPLATESEDIT"}
        path={"/templates/edit/:id"}
        exact={false}
        element={
          <RequireAuth>
            <EditTemplate />
          </RequireAuth>
        }
      />

    <Route
        key={"USERS"}
        path={"/users"}
        exact={false}
        element={
          <RequireAuth>
            <Users />
          </RequireAuth>
        }
      />
    <Route
        key={"CAMPAIGNTEMPLATE"}
        path={"/templates"}
        exact={false}
        element={
          <RequireAuth>
            <CampaignTemplates />
          </RequireAuth>
        }
      />
    <Route
        key={"AUDIENCES"}
        path={"/audiences"}
        exact={false}
        element={
          <RequireAuth>
            <Audiences />
          </RequireAuth>
        }
      />

    <Route
        key={"BLOCKCONTACTSBYGLOBAL"}
        path={"/block-contacts/global"}
        element={
          <RequireAuth>
            <BlockContactsByGlobal />
          </RequireAuth>
        }
      />

<Route
        key={"BLOCKCONTACTSBYCAMPAIGN"}
        path={"/block-contacts/campaign"}
        element={
          <RequireAuth>
            <BlockContactsByCampaign />
          </RequireAuth>
        }
      />

<Route
        key={"BLOCKCONTACTSBYTAGS"}
        path={"/block-contacts/tags"}
        element={
          <RequireAuth>
            <BlockContactsByTags />
          </RequireAuth>
        }
      />
      <Route key={"REPORT"}
      path="/campaign/report"
      element={
        <RequireAuth>
        <Report/>
        </RequireAuth>
      }>
      </Route>
      <Route key={"SINGLEREPORT"}
      path="/campaign/report/:campaignId"
      element={
        <RequireAuth>
        <CampaignReportPage/>
        </RequireAuth>
      }>
      </Route>
      <Route component={() => <h1>Not Found!</h1>} path="/not-found" />
    </Routes>
  );
}
