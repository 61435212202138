import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {setMessage} from "../message";
import CampaignsService from "../../../services/campagins";
import {toast} from "react-toastify";

export const getCampaigns = createAsyncThunk(
  "campagins/getCampagins",
  async ({page, filterData}, thunkAPI) => {
    try {
      const result = await CampaignsService.getCampaigns(page, filterData);
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "campagins/deleteCampaign",
  async (payload, thunkAPI) => {
    try {
      const result = await CampaignsService.deleteCampaign(payload);
      if (result) {
        toast.success("Campaign deleted successfully.");
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error("Ops, something went wrong.");
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const cloneCampaign = createAsyncThunk(
  "campagins/cloneCampaign",
  async (payload, thunkAPI) => {
    try {
      const result = await CampaignsService.cloneCampaign(payload);
      if (result) {
        toast.success("Campaign cloned successfully.");
        return result;
      } else {
        toast.error("Ops, something went wrong.");
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const testCampaigns = createAsyncThunk(
  "campagins/testCampagins",
  async (payload, thunkAPI) => {
    try {
      const result = await CampaignsService.testCampaign(payload);
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {campaigns: null};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,

  extraReducers: {
    [getCampaigns.fulfilled]: (state, action) => {
      console.log("getCampaigns.fulfilled", action);
      state.campaigns = action.payload;
      //return { ...state, defaulters: action.payload.data };
    },
    [getCampaigns.rejected]: (state, action) => {
      state.campaigns = null;
      //return { ...state, defaulters: [] };
    },
    [cloneCampaign.fulfilled]: (state, action) => {
      console.log("getCampaigns.fulfilled", action);
      state.campaigns = action.payload;
      //return { ...state, defaulters: action.payload.data };
    },
    [cloneCampaign.rejected]: (state, action) => {
      state.campaigns = null;
      //return { ...state, defaulters: [] };
    },
    [testCampaigns.rejected]: (state, action) => {
      state.campaigns = null;
      //return { ...state, defaulters: [] };
    },
  },
});

export default campaignsSlice.reducer;
