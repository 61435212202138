import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import AUdiencesService from "../../../services/audiences";

export const getAudience = createAsyncThunk(
  "audiences/getAudience",
  async (id, thunkAPI) => {
    try {
      const result = await AUdiencesService.getAudience(id);
      console.log('getAudience ######- ', result)
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { audience: {} };

const audienceSlice = createSlice({
  name: "audience",
  initialState,
  reducers:{
    resetAudience(state, _){
        state.audience = null
    },
    
  },

  extraReducers: {
    [getAudience.fulfilled]: (state, action) => {
      console.log('getAudience.fulfilled####...', action.payload)
      state.audience = action.payload;
    },
    [getAudience.rejected]: (state, action) => {
      console.log('getAudience rejected details...', action.payload)
      state.audience = null;
    },
  },
});

export const audienceActions = audienceSlice.actions;

export default audienceSlice.reducer;
