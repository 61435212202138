import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getTemplates = async (page, filterData, isAll) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { page, filterData, isAll };
    console.log("data templates", data);
    let result = await apiService.post(API_URL.GET_TEMPLATES, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getTemplate = async (id) => {
  try {
    
    let token = getToken();

    let result = await apiService.get(API_URL.GET_TEMPLATE+id, token);
    return result;
    
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const createTemplate = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { ...payload };
    console.log("CREATE campaign data #okkkkkkk", data);
    let result = await apiService.post(API_URL.TEMPLATE_CREATE, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const updateTemplate = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = { ...payload };
    console.log("data", data);
    let result = await apiService.put(API_URL.TEMPLATE_UPDATE, data, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const deleteTemplate = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    
    let result = await apiService.deleteData(API_URL.GET_TEMPLATE+payload, token);
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const cloneTemplate = async (payload) => {
  try {
    let data = {};

    let token = getToken();

    //if (gstn) {console
    data = {...payload};
    console.log("clone template #okkkkkkk", data);
    let result = await apiService.post(API_URL.TEMPLATE_CLONE, data, token);
    return result;
    // }
  } catch (e) {
    return {status: false, data: {}, message: e.message};
  }
};

const templatesService = {
    getTemplates,
    getTemplate,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    cloneTemplate
};

export default templatesService;
