import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import templatesService from "../../../services/templates";
import {toast} from "react-toastify"

export const createTemplate = createAsyncThunk(
  "campagins/createTemplate",
  async (payload, thunkAPI) => {
    try {
        console.log('create templates all thunk calling....')
      const result = await templatesService.createTemplate(
        payload
      );
      if (!result?.data?.error) {

        toast.success("Template created successfully.")
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateTemplate = createAsyncThunk(
  "campagins/updateTemplate",
  async (payload , thunkAPI) => {
    try {
      console.log('updating template data from thunk function ', payload)
      const result = await templatesService.updateTemplate(
        payload
      );
      if (!result?.data?.error) {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.success(result.data.message)
        return result;

      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  "templates/deleteTemplate",
  async (payload, thunkAPI) => {
    try {
        console.log('create user all thunk calling....', payload)
      const result = await templatesService.deleteTemplate(
        payload
      );
      if (!result?.data?.error) {
        toast.success(result.data.message)
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message)
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { 
  template: null,
  templateCreated:null,
  reloadTemplates:null
};

const createTemplateSlice = createSlice({
  name: "template",
  initialState,
  reducers:{
    reSetTemplate(state, _){
      state.template = null 
      state.templateCreated = null
    }
  },
  extraReducers: {
    [createTemplate.fulfilled]: (state, action) => {
      console.log("createTemplate.fulfilled...", action.payload);
      state.templateCreated=true;
      state.template = action.payload;
      state.reloadTemplates = !state.reloadTemplates
    },
    [createTemplate.rejected]: (state, action) => {
      state.template = null;
      state.templateCreated=null;
    },
    [updateTemplate.fulfilled]: (state, action) => {
      console.log("update.fulfilled okkkkkkkkkk", action);
      state.templateCreated=true;
      state.template = action.payload;
      state.reloadTemplates = !state.reloadTemplates
    },
    [updateTemplate.rejected]: (state, action) => {
      //state.campaign = null;
      state.templateCreated=null;
    },
    [deleteTemplate.fulfilled]: (state, action) => {
      console.log("deleteTemplate.fulfilled okkkkkkkkkk", action);
      state.reloadTemplates = !state.reloadTemplates
    },
    [deleteTemplate.rejected]: (state, action) => {
      //state.campaign = null;
      console.log("deleteTemplate.rejected okkkkkkkkkk", action);
    }
  },
});

export const createTemplateActions = createTemplateSlice.actions
export default createTemplateSlice.reducer;
